import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {DisplayAccountNotificationData, JsonWrappedValue, PagedData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.NotificationController
 */

@Injectable()
@ResourceParams({})
export class NotificationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/notification/{notificationId}/delete',
        endpoint: 'cronos'
    })
    private _deleteNotification: IResourceMethodObservableStrict<void, {}, {notificationId: number}, void>;
    deleteNotification(notificationId: number): Promise<void> {
        return this._deleteNotification(null, null, {notificationId: notificationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/notification/own',
        endpoint: 'cronos'
    })
    private _getOwnNotifications: IResourceMethodObservableStrict<void, {page: number, pageSize: number, unseenOnly?: boolean}, {}, PagedData<DisplayAccountNotificationData>>;
    getOwnNotifications(requestParams: {page: number, pageSize: number, unseenOnly?: boolean}): Promise<PagedData<DisplayAccountNotificationData>> {
        return this._getOwnNotifications(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/notification/own/count',
        endpoint: 'cronos'
    })
    private _getOwnNotificationsCount: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<number>>;
    getOwnNotificationsCount(): Promise<JsonWrappedValue<number>> {
        return this._getOwnNotificationsCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/notification/{notificationId}/seen',
        endpoint: 'cronos'
    })
    private _seenSingleNotification: IResourceMethodObservableStrict<void, {}, {notificationId: number}, void>;
    seenSingleNotification(notificationId: number): Promise<void> {
        return this._seenSingleNotification(null, null, {notificationId: notificationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/notification/own/setAllDeleted',
        endpoint: 'cronos'
    })
    private _setOwnNotificationsDeleted: IResourceMethodObservableStrict<void, {}, {}, void>;
    setOwnNotificationsDeleted(): Promise<void> {
        return this._setOwnNotificationsDeleted(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/notification/own/setAllSeen',
        endpoint: 'cronos'
    })
    private _setOwnNotificationsSeen: IResourceMethodObservableStrict<void, {}, {}, void>;
    setOwnNotificationsSeen(): Promise<void> {
        return this._setOwnNotificationsSeen(null, null, null).toPromise()
    }

}
