<div class="checkout-header" fxLayout="row" fxLayoutAlign="center start">
  <div class="first-col">
  <div class="section-header" style="transform: translateY(13px)"><h1 style="font-size: 23.4px"><strong>Nur noch 2 Schritte.</strong></h1>
    <div style="color: #999">Zur Verbesserung unseres Angebotes benötigen wir deine PLZ.</div>
  </div>

<div class="s3-checkout-body">
  <form class="plz-wrapper">
    <hid-input-field [autoFocus]="true" [forceValidation]="nextStepAttempted" [control]="zipCodeControl"
                     [showValidatorIcons]="true" [validationPending]="searchingZipCode"
                     type="number" name="zipCode" label="PLZ"
    ></hid-input-field>

    <button type="submit"
      class="hid-button primary"
      (click)="saveZipCodeAndContinue()"
    >Weiter
    </button>
  </form>
  <div *ngIf="!zipCodeFound && zipCodeSearched" class="nearby-cities-container">
    <div style="color: #999"><p>Leider befindet sich diese PLZ nicht in unserer Datenbank. Bitte wähle den
      nächstgelegenen Ort.</p>


      <app-geolocation (result)="displayCities($event)" [searchImmediately]="true"></app-geolocation>

    </div>
    <div class="cityItem" [ngClass]="{'fade-in':matchingCities.length >= 1}" *ngFor="let city of matchingCities">
      <div>{{city.name}}</div>
      <button class="city-button" (click)="continueWithNearestLocationToCity(city.id)"
      >Auswählen
      </button>
    </div>
  </div>
</div>
  </div>
  <div class="second-col" style="transform: translateY(12px)">
    <button class="hid-icon-button inverse ita_close_checkout-modal" (click)="globalCheckoutService.returnToOfferPage()">
      <i
        class="fas fa-times"></i>
    </button>
  </div>
</div>
