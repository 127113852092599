import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-giveaway-conditions-tax',
  templateUrl: './giveaway-conditions-tax.component.html',
  styleUrls: ['./giveaway-conditions-tax.component.scss']
})
export class GiveawayConditionsTaxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
