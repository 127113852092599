import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {CareerArticleData, CareerArticleSplitData, JsonWrappedValue, PagedData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.AdminCareerArticleController
 */

@Injectable()
@ResourceParams({})
export class AdminCareerArticleResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/careerArticle/create',
        endpoint: 'cms'
    })
    private _createArticle: IResourceMethodObservableStrict<CareerArticleData, {}, {}, CareerArticleData>;
    createArticle(requestBody: CareerArticleData): Promise<CareerArticleData> {
        return this._createArticle(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/careerArticle/{id}/splits/create',
        endpoint: 'cms'
    })
    private _createNewArticleSplit: IResourceMethodObservableStrict<JsonWrappedValue<number>, {internalTitle: string}, {id: number}, JsonWrappedValue<number>>;
    createNewArticleSplit(requestBody: JsonWrappedValue<number>, id: number, requestParams: {internalTitle: string}): Promise<number> {
        return this._createNewArticleSplit(requestBody, requestParams, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/careerArticles/preview',
        endpoint: 'cms'
    })
    private _createTemporarySplitPreview: IResourceMethodObservableStrict<CareerArticleSplitData, {}, {}, JsonWrappedValue<string>>;
    createTemporarySplitPreview(requestBody: CareerArticleSplitData): Promise<string> {
        return this._createTemporarySplitPreview(requestBody, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/careerArticle/{id}/delete',
        endpoint: 'cms'
    })
    private _deleteArticle: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteArticle(id: number): Promise<void> {
        return this._deleteArticle(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/careerArticle/{articleId}/splits/{splitId}/delete',
        endpoint: 'cms'
    })
    private _deleteArticleSplit: IResourceMethodObservableStrict<void, {}, {articleId: number, splitId: number}, void>;
    deleteArticleSplit(pathVariables: {articleId: number, splitId: number}): Promise<void> {
        return this._deleteArticleSplit(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/all',
        endpoint: 'cms'
    })
    private _getAllArticle: IResourceMethodObservableStrict<void, {page: number, pageSize: number, activeStates: boolean[], q?: string}, {}, PagedData<CareerArticleData>>;
    getAllArticle(requestParams: {page: number, pageSize: number, activeStates: boolean[], q?: string}): Promise<PagedData<CareerArticleData>> {
        return this._getAllArticle(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/routingurls',
        endpoint: 'cms'
    })
    private _getAllRoutingUrls: IResourceMethodObservableStrict<void, {}, {}, string[]>;
    getAllRoutingUrls(): Promise<string[]> {
        return this._getAllRoutingUrls(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/{id}',
        endpoint: 'cms'
    })
    private _getArticle: IResourceMethodObservableStrict<void, {}, {id: number}, CareerArticleData>;
    getArticle(id: number): Promise<CareerArticleData> {
        return this._getArticle(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/{splitId}/split',
        endpoint: 'cms'
    })
    private _getArticleSplit: IResourceMethodObservableStrict<void, {}, {splitId: number}, CareerArticleSplitData>;
    getArticleSplit(splitId: number): Promise<CareerArticleSplitData> {
        return this._getArticleSplit(null, null, {splitId: splitId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/splits/{splitId}/banner',
        endpoint: 'cms'
    })
    private _getArticleSplitBanner: IResourceMethodObservableStrict<void, {}, {splitId: number}, Blob>;
    getArticleSplitBanner(splitId: number): Promise<Blob> {
        return this._getArticleSplitBanner(null, null, {splitId: splitId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/splits/{splitId}/cardBanner',
        endpoint: 'cms'
    })
    private _getArticleSplitCardBanner: IResourceMethodObservableStrict<void, {}, {splitId: number}, Blob>;
    getArticleSplitCardBanner(splitId: number): Promise<Blob> {
        return this._getArticleSplitCardBanner(null, null, {splitId: splitId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/careerArticle/{articleId}/splits',
        endpoint: 'cms'
    })
    private _getArticleSplits: IResourceMethodObservableStrict<void, {}, {articleId: number}, CareerArticleSplitData[]>;
    getArticleSplits(articleId: number): Promise<CareerArticleSplitData[]> {
        return this._getArticleSplits(null, null, {articleId: articleId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/careerArticle/{id}/update',
        endpoint: 'cms'
    })
    private _updateArticle: IResourceMethodObservableStrict<CareerArticleData, {}, {id: number}, CareerArticleData>;
    updateArticle(requestBody: CareerArticleData, id: number): Promise<CareerArticleData> {
        return this._updateArticle(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/careerArticle/{id}/updateActive',
        endpoint: 'cms'
    })
    private _updateArticleActive: IResourceMethodObservableStrict<void, {active: boolean}, {id: number}, JsonWrappedValue<boolean>>;
    updateArticleActive(id: number, requestParams: {active: boolean}): Promise<boolean> {
        return this._updateArticleActive(null, requestParams, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/careerArticle/{articleId}/splits/{splitId}/update',
        endpoint: 'cms'
    })
    private _updateArticleSplit: IResourceMethodObservableStrict<CareerArticleSplitData, {}, {articleId: number, splitId: number}, void>;
    updateArticleSplit(requestBody: CareerArticleSplitData, pathVariables: {articleId: number, splitId: number}): Promise<void> {
        return this._updateArticleSplit(requestBody, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/careerArticle/{id}/images',
        endpoint: 'cms'
    })
    private _updateArticleSplitImages: IResourceMethodObservableStrict<void, {bannerImage: File, bannerCardImage: File}, {id: number}, void>;
    updateArticleSplitImages(id: number, requestParams: {bannerImage: File, bannerCardImage: File}): Promise<void> {
        return this._updateArticleSplitImages(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/careerArticle/{articleId}/splits/defaultSplit',
        endpoint: 'cms'
    })
    private _updateDefaultArticleSplit: IResourceMethodObservableStrict<void, {defaultSplitId: number}, {articleId: number}, void>;
    updateDefaultArticleSplit(articleId: number, requestParams: {defaultSplitId: number}): Promise<void> {
        return this._updateDefaultArticleSplit(null, requestParams, {articleId: articleId}).toPromise()
    }

}
