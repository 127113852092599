import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {
  CreateFormatPageSplitData,
  FormatPageSplitData,
  InternalAbstractFormatPageData,
  InternalRedirectOfferPageData,
  InternalSingleFormatPageData,
  JsonWrappedValue,
  LabelData,
  NumberData,
  PagedData
} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalFormatPageController
 */

@Injectable()
@ResourceParams({})
export class InternalFormatPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/formats/{fid}/splits',
        endpoint: 'cms'
    })
    private _createNewFormatPageSplit: IResourceMethodObservableStrict<CreateFormatPageSplitData, {}, {fid: number}, JsonWrappedValue<number>>;
    createNewFormatPageSplit(requestBody: CreateFormatPageSplitData, fid: number): Promise<number> {
        return this._createNewFormatPageSplit(requestBody, null, {fid: fid}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/preview',
        endpoint: 'cms'
    })
    private _createTemporarySplitPreview: IResourceMethodObservableStrict<FormatPageSplitData, {}, {}, JsonWrappedValue<string>>;
    createTemporarySplitPreview(requestBody: FormatPageSplitData): Promise<string> {
        return this._createTemporarySplitPreview(requestBody, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/offerPages/formats/{fid}/splits/{sid}',
        endpoint: 'cms'
    })
    private _deleteSplitForFormat: IResourceMethodObservableStrict<void, {}, {fid: number, sid: number}, void>;
    deleteSplitForFormat(pathVariables: {fid: number, sid: number}): Promise<void> {
        return this._deleteSplitForFormat(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formats/{id}',
        endpoint: 'cms'
    })
    private _getFormatPage: IResourceMethodObservableStrict<void, {}, {id: number}, InternalAbstractFormatPageData>;
    getFormatPage(id: number): Promise<InternalAbstractFormatPageData> {
        return this._getFormatPage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formats',
        endpoint: 'cms'
    })
    private _getFormatPages: IResourceMethodObservableStrict<void, {q: string, showWithoutSplits: boolean, page?: number, pageSize?: number}, {}, PagedData<InternalSingleFormatPageData>>;
    getFormatPages(requestParams: {q: string, showWithoutSplits: boolean, page?: number, pageSize?: number}): Promise<PagedData<InternalSingleFormatPageData>> {
        return this._getFormatPages(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formats/labels',
        endpoint: 'cms'
    })
    private _getFormatPagesLabelData: IResourceMethodObservableStrict<void, {}, {}, LabelData[]>;
    getFormatPagesLabelData(): Promise<LabelData[]> {
        return this._getFormatPagesLabelData(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/redirect-pages',
        endpoint: 'cms'
    })
    private _getRedirectOfferPagesByQuery: IResourceMethodObservableStrict<void, {q: string, showWithoutSplits: boolean, page?: number, pageSize?: number}, {}, PagedData<InternalRedirectOfferPageData>>;
    getRedirectOfferPagesByQuery(requestParams: {q: string, showWithoutSplits: boolean, page?: number, pageSize?: number}): Promise<PagedData<InternalRedirectOfferPageData>> {
        return this._getRedirectOfferPagesByQuery(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formats/{id}/splits',
        endpoint: 'cms'
    })
    private _getSplitsForFormat: IResourceMethodObservableStrict<void, {}, {id: number}, FormatPageSplitData[]>;
    getSplitsForFormat(id: number): Promise<FormatPageSplitData[]> {
        return this._getSplitsForFormat(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formats/{id}/queriedSplits',
        endpoint: 'cms'
    })
    private _getSplitsForFormatByQuery: IResourceMethodObservableStrict<void, {query: string}, {id: number}, FormatPageSplitData[]>;
    getSplitsForFormatByQuery(id: number, requestParams: {query: string}): Promise<FormatPageSplitData[]> {
        return this._getSplitsForFormatByQuery(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerpages/formats/{id}/additionalCheckoutFormats',
        endpoint: 'cms'
    })
    private _updateAdditionalCheckoutFormats: IResourceMethodObservableStrict<JsonWrappedValue<number[]>, {}, {id: number}, void>;
    updateAdditionalCheckoutFormats(requestBody: JsonWrappedValue<number[]>, id: number): Promise<void> {
        return this._updateAdditionalCheckoutFormats(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/{fid}/splits/likelyhoods',
        endpoint: 'cms'
    })
    private _updateDefaultSplitForFormatPage: IResourceMethodObservableStrict<number, {}, {fid: number}, void>;
    updateDefaultSplitForFormatPage(requestBody: number, fid: number): Promise<void> {
        return this._updateDefaultSplitForFormatPage(requestBody, null, {fid: fid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/{id}',
        endpoint: 'cms'
    })
    private _updateFormatPage: IResourceMethodObservableStrict<InternalAbstractFormatPageData, {}, {id: number}, void>;
    updateFormatPage(requestBody: InternalAbstractFormatPageData, id: number): Promise<void> {
        return this._updateFormatPage(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/{id}/updactive',
        endpoint: 'cms'
    })
    private _updateFormatPageActive: IResourceMethodObservableStrict<void, {active: boolean}, {id: number}, JsonWrappedValue<boolean>>;
    updateFormatPageActive(id: number, requestParams: {active: boolean}): Promise<boolean> {
        return this._updateFormatPageActive(null, requestParams, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/{fid}/splits/{sid}/image',
        endpoint: 'cms'
    })
    private _updateFormatPageImage: IResourceMethodObservableStrict<NumberData, {}, {fid: number, sid: number}, void>;
    updateFormatPageImage(requestBody: NumberData, pathVariables: {fid: number, sid: number}): Promise<void> {
        return this._updateFormatPageImage(requestBody, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/formats/{fid}/splits/{sid}',
        endpoint: 'cms'
    })
    private _updateSplitForFormat: IResourceMethodObservableStrict<FormatPageSplitData, {}, {fid: number, sid: number}, void>;
    updateSplitForFormat(requestBody: FormatPageSplitData, pathVariables: {fid: number, sid: number}): Promise<void> {
        return this._updateSplitForFormat(requestBody, null, pathVariables).toPromise()
    }

}
