import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrolledToBottomDirective} from "./scrolled-to-bottom/scrolled-to-bottom.directive";
import {MediaService} from "./media.service";
import {PromotionTypePipe} from "./pipes/promotion-type.pipe";
import {FeedbackPromotiontypePhrasingPipe} from './pipes/feedback-promotiontype-phrasing.pipe';
import {
  PromotionFormatTypeToPromotionTypeDisplayPipe
} from './pipes/promotion-format-type-to-promotion-type-display.pipe';
import {UserOfflineDialogComponent} from './dialogs/user-offline-dialog/user-offline-dialog.component';
import {GenderPipe} from './pipes/gender.pipe';
import {ReleaseLevelPipe} from './pipes/release-level.pipe';
import {BadFeedbackReasonPipe} from './pipes/bad-feedback-reason.pipe';
import {OptOutReasonPipe} from './pipes/opt-out-reason.pipe';

@NgModule({
  declarations: [ScrolledToBottomDirective,
  PromotionTypePipe,
  FeedbackPromotiontypePhrasingPipe,
  PromotionFormatTypeToPromotionTypeDisplayPipe,
  UserOfflineDialogComponent,
  GenderPipe,
  ReleaseLevelPipe,
    BadFeedbackReasonPipe,
    OptOutReasonPipe],
  providers:[
    MediaService
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ScrolledToBottomDirective,
    PromotionTypePipe,
    FeedbackPromotiontypePhrasingPipe,
    PromotionFormatTypeToPromotionTypeDisplayPipe,
    UserOfflineDialogComponent,
    GenderPipe,
    ReleaseLevelPipe,
    BadFeedbackReasonPipe,
    OptOutReasonPipe
  ]
})
export class InnoUtilsModule {


}

export function arrayContains(array: any[], value: any) {
  return array.indexOf(value) > -1
}

export function pushOrRemove(array: any[], value:any){
  if(arrayContains(array, value)){
    array.splice(array.indexOf(value),1)
  }else{
    array.push(value)
  }
}
