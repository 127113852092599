import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutS3aProcessService} from '../seminar-checkout-s3a-process.service';

@Component({
  selector: 'app-seminar-checkout-s3a-entry',
  templateUrl: './seminar-checkout-s3a-entry.component.html',
  styleUrls: ['./seminar-checkout-s3a-entry.component.sass']
})
export class SeminarCheckoutS3aEntryComponent implements OnInit {


  constructor(private router: Router, private route: ActivatedRoute,
              private s3ProcessService: SeminarCheckoutS3aProcessService) {
  }


  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.s3ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s3a/location')]);
  }

  returnToOfferPage(){
  }


}
