import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AccountResource} from "../../../generated/cronos/resources";
import {UtilsService} from "../../../utils.service";
import {HidSnackbarService} from "../../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {AccountInfoData} from "../../../generated/cronos/data";
import {SidenavService} from "../../account-sidenav/sidenav.service";

@Component({
  selector: 'app-edit-avatar-dialog',
  templateUrl: './edit-avatar-dialog.component.html',
  styleUrls: ['./edit-avatar-dialog.component.scss']
})
export class EditAvatarDialogComponent implements OnInit {

  avatarList: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,20,21]
  chosenAvatar: number = 0;
  profileInfo: AccountInfoData
  avatarChanged: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogref: MatDialogRef<EditAvatarDialogComponent>,
    private accountResource: AccountResource,
    public utilService: UtilsService
  ) {
    this.profileInfo = data
  }

  ngOnInit(): void {
    this.utilService.reportDialogChange(true)
  }

  changeAvatarId(avatarId: number){
    this.avatarChanged = true
    this.chosenAvatar = avatarId
  }

  saveAndExit(){
    this.utilService.reportDialogChange(false)
    this.dialogref.close(this.chosenAvatar)
  }

  close(){
    this.utilService.reportDialogChange(false)
    this.dialogref.close(0)
  }
}
