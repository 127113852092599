import { Component, OnInit } from '@angular/core';
import {AccountResource, QuestionResource} from "../../generated/cronos/resources";
import {AccountInfoData, ExpertProgressData} from "../../generated/cronos/data";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {UtilsService} from "../../utils.service";
import {MatDialog} from "@angular/material/dialog";
import {EditAvatarDialogComponent} from "./edit-avatar-dialog/edit-avatar-dialog.component";
import {Router} from "@angular/router";
import {MediaService} from "../../media.service";
import {SidenavService} from "../account-sidenav/sidenav.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  showPasswordHint: boolean = false;

  avatarId: number = 0

  avatarHover: boolean = false

  nameIsChanged: boolean = false
  mailIsChanged: boolean = false

  constructor(
    private accountResource: AccountResource,
    private questionResource: QuestionResource,
    private hidSnackbarService: HidSnackbarService,
    public utilService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private mediaService: MediaService,
    private sidenavService: SidenavService
  ) { }

  info: AccountInfoData

  ngOnInit(): void {
    this.loadProfileData()
  }

  loadProfileData(){
    this.accountResource.getInfo().then(result=>{
      this.info = result
    })
  }

  editName(){
    this.accountResource.setAccountName({displayName: this.info.firstName}).then(() => {
      this.hidSnackbarService.openSnackBar('Name erfolgreich angepasst.', 'Ok', 2000, false)
    })
  }

  editMail(){
    this.accountResource.setAccountEmail({emailAddress: this.info.emailAddress}).then(() => {
      this.hidSnackbarService.openSnackBar('E-Mail-Adresse erfolgreich angepasst.', 'Ok', 2000, false)
    })
  }

  saveEdits(){
    if(this.nameIsChanged){
      this.editName()
    }
    if(this.mailIsChanged){
      this.editMail()
    }
  }

  openAvatarEditDialog(){
    if(this.mediaService.isMobile()){
      const dialogRef = this.dialog.open(EditAvatarDialogComponent, {
        data: this.info,
        panelClass: 'fullscreen-dialog'
      });
      dialogRef.afterClosed().subscribe(chosenAvatar => {
        if(chosenAvatar != 0 && chosenAvatar != this.info?.avatarId){
          this.accountResource.setAvatarId({avatarId: chosenAvatar}).then(() => {
            this.sidenavService.reportProfilePicChange()
            this.hidSnackbarService.openSnackBar('Avatar angepasst.', 'Ok', 2000, false)
            this.loadProfileData()
          })
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(EditAvatarDialogComponent, {
        data: this.info
      });
      dialogRef.afterClosed().subscribe(chosenAvatar => {
        if(chosenAvatar != 0 && chosenAvatar != this.info?.avatarId){
          this.accountResource.setAvatarId({avatarId: chosenAvatar}).then(() => {
            this.sidenavService.reportProfilePicChange()
            this.hidSnackbarService.openSnackBar('Avatar angepasst.', 'Ok', 2000, false)
            this.loadProfileData()
          })
        }
      })
    }

  }

}
