<div style="padding:45px 30px"><div id="WACViewPanel_ClipboardElement" tabindex="0"><div><div><h1>Teilnahmebedingungen </h1></div><div></div>
  <div><h2>Gegenstand der Teilnahmebedingungen und Veranstalter </h2></div>
  <div></div><div><ol
    start="1"><li><p>Diese Teilnahmebedingungen regeln die Bedingungen für eine Teilnahme an dem Gewinnspiel über 1 iPad.</p></li></ol></div></div><div><div></div><div><ol
  start="2"><li><p>Veranstalter des Gewinnspiels ist die </p></li></ol></div><div></div><div><p>Uniwunder GmbH </p></div><div><p>Bergstraße 29 </p></div></div><div><div><p>01069 Dresden </p></div><div><p><a
  href="mailto:info@hochschulinitiative-deutschland.de" target="_blank" rel="noreferrer noopener">info@hochschulinitiative-deutschland.de</a> </p></div><div></div><div><ol
  start="3"><li><p>Mit der Teilnahme an dem Gewinnspiel werden diese Teilnahmebedingungen angenommen.  </p></li></ol></div><div></div></div><div>
  <div><ol
    start="4"><li><p>Das Gewinnspiel steht in keiner Verbindung zu Instagram, Facebook, TikTok oder anderen Social-Media-Kanälen, es wird weder von Instagram, Facebook oder TikTok gesponsort, unterstützt noch organisiert. </p></li></ol></div><div></div>
  <div><h2>Teilnahme </h2></div>
  <div></div><div><ol start="1"><li><p>Teilnahmeberechtigt am Gewinnspiel sind alle Studierende, welche ihren Wohnsitz in Deutschland haben. Nicht teilnahmeberechtigt sind alle an der Konzeption und Umsetzung des Gewinnspiels beteiligte Personen und Mitarbeiter des Veranstalters sowie ihre Familienmitglieder. </p></li></ol></div></div><div><div></div><div><ol
  start="2"><li><p>Sollte ein Teilnehmer in seiner Geschäftsfähigkeit eingeschränkt sein, bedarf es der Einwilligung eines gesetzlichen Vertreters. </p></li></ol></div><div></div><div><ol
  start="3"><li><p>Die Teilnahme mit gefälschten Identitäten oder mit Identitäten von Dritten ist nicht erlaubt. Zudem behält sich der Veranstalter vor, nach eigenem Ermessen Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe vorliegen, beispielsweise (a) bei Manipulation im Zusammenhang mit dem Zugang zum oder Durchführung des Gewinnspiels, (b) bei Verstößen gegen diese Teilnahmebedingungen, (c) bei unlauterem Handeln oder (d) bei falschen oder irreführenden Angaben im Zusammenhang mit der Teilnahme an dem Gewinnspiel. </p></li></ol></div><div></div></div><div><div><ol
  start="4"><li><p>Teilnahmeberechtigte können an dem Gewinnspiel teilnehmen (nachfolgend „Teilnehmer“),
  indem sie zwischen dem 24.06.2023 und dem 01.09.2023 an einem Webinar zum Thema
  Excel (<a href="https://hochschulinitiative-deutschland.de/angebote/excel-webinar" target="_blank" rel="noreferrer noopener">https://hochschulinitiative-deutschland.de/angebote/excel-webinar</a>)
  oder einem Webinar zum Thema Finanzen (<a href="https://hochschulinitiative-deutschland.de/angebote/finanz-crashkurs-fur-studenten" target="_blank" rel="noreferrer noopener">https://hochschulinitiative-deutschland.de/angebote/finanz-crashkurs-fur-studenten</a>)
  <b>teilgenommen</b> haben und ihre E-Mail-Adresse über den Bestätigungslink in der Willkommensmail <b>bestätigt</b> haben. Personen, die diese Voraussetzungen nicht erfüllen, können nicht am Gewinnspiel teilnehmen.  </p></li></ol></div><div></div><div><ol
  start="5"><li><p>Die Teilnahme ist bis zum 01.09.2023 möglich. </p></li></ol></div>
  <div><h2>Gewinne und Gewinnbenachrichtigung </h2></div>
  <div></div></div>
  <div><div><ol start="1"><li><p>Unter allen teilnahmeberechtigten Teilnehmern, welche zwischen dem 24.06.2023 und dem 01.09.2023 an einem der in § 2 Nr. 4 genannten Webinare teilgenommen haben und ihre E-Mail-Adresse über den Bestätigungslink in der Willkommensmail bestätigt haben, verlosen wir im Rahmen des Gewinnspiels ein iPad gemäß des nachfolgenden Bedingungen: </p></li></ol></div><div></div>
    <div style="padding-left: 20px"><ol type="I" start="1"><li><p>Die Ermittlung der Gewinner erfolgt nach Teilnahmeschluss im Rahmen einer auf dem Zufallsprinzip beruhenden Verlosung unter allen Teilnehmern, welche die zuvor genannten Voraussetzungen erfüllen. </p></li></ol></div><div></div>
    <div style="padding-left: 20px"><ol type="I" start="2"><li><p>Die ausgelosten Gewinner übersenden uns ein Foto, welches den Gewinner und das gewonnene iPad abbildet. Sollte kein Foto eingesendet werden, so kann der Gewinn nachträglich aberkannt werden. </p></li></ol></div><div></div>
    <div><ol start="2"><li><p>Die Gewinner werden per E-Mail über Ihren Gewinn benachrichtigt und aufgefordert eine E-Mail zu senden, in der die persönlichen Daten ordnungs- und wahrheitsgemäß vollständig übermittelt werden. Diese Angaben dienen der Übersendung des Gewinns. Für die Richtigkeit der angegebenen E-Mail-Adresse sind die Teilnehmer verantwortlich.  </p></li></ol></div></div><div><div></div><div><ol
    start="3"><li><p>Geht von einem Gewinner innerhalb der angegebenen Frist keine E-Mail bei uns ein, erlischt der Gewinnanspruch. Wir sind in diesem Fall berechtigt, eine Ersatzauslosung durchzuführen. Sollte keine Frist angegeben sein, gilt eine Dauer von 3 Wochen nach Zugang der Gewinnbenachrichtigungsmail als gesetzte Frist. </p></li></ol></div><div></div><div><ol
    start="4"><li><p>Je Teilnehmer ist nur ein Gewinn möglich.  </p></li></ol></div><div></div></div><div><div><ol
    start="5"><li><p>Der Gewinn ist weder übertragbar, noch kann der Gewinn getauscht oder in bar ausgezahlt werden. </p></li></ol></div><div></div><div><ol
    start="6"><li><p>Ein Rechtsanspruch auf die Gewinne wird mit der Teilnahme nicht begründet. </p></li></ol></div>

    <div><h2>Nutzungsrechtseinräumung</h2></div>
    <div></div><div><ol start="1"><li><p>Der Teilnehmer garantiert, Inhaber der erforderlichen Rechte an dem eingesendeten Foto zu sein.</p></li></ol></div>
    <div></div><div><ol start="2"><li><p>Der Teilnehmer räumt uns an dem von ihm eingesendeten Foto nachfolgende einfache, zeitlich und räumlich unbegrenzte aber nicht exklusive Nutzungsrechte ein:</p></li></ol></div>
    <div></div><div style="padding-left: 20px"><ul><li><p>das Recht zur Speicherung des Fotos auf einem Server;</p></li></ul></div>
    <div></div><div style="padding-left: 20px"><ul><li><p>das Recht, das Foto der Öffentlichkeit ganz oder teilweise zugänglich zu machen;</p></li></ul></div>
    <div></div><div style="padding-left: 20px"><ul><li><p>das Recht, das Foto zur Bewerbung unserer Produkte zu nutzen;</p></li></ul></div>
    <div></div><div style="padding-left: 20px"><ul><li><p>das Recht zur Bearbeitung des Fotos, wobei das Bild nicht verfremdet werden darf.</p></li></ul></div>
    <div></div><div><ol start="3"><li><p>Wir werden bei der Verwendung des Bildes in geeigneter Weise den Urheber kenntlich machen, soweit dies unter Berücksichtigung der konkreten Verwendungsform tunlich ist.</p></li></ol></div>
    <div></div><div><ol start="4"><li><p>Mit der Annahme des Gewinns willigt der Gewinner ein, dass wir seinen Namen zu Werbezwecken verwenden dürfen.</p></li></ol></div>

    <div><h2>Haftung und Freistellung</h2></div>
    <div></div><div><ol start="1"><li><p>Sofern der Teilnehmer ein Foto einsendet, garantiert der Teilnehmer, dass er keine Inhalte übersenden wird, deren Bereitstellung, Veröffentlichung oder Nutzung gegen geltendes Recht oder Rechte Dritter verstößt.</p></li></ol></div>
    <div></div><div><ol start="2"><li><p>Der Teilnehmer stellt uns von Ansprüchen Dritter gleich welcher Art frei, die aus der Rechtswidrigkeit des Fotos resultieren, welches der Teilnehmer verwendet hat. Die Freistellungsverpflichtung umfasst auch die Verpflichtung, den Veranstalter von Rechtsverteidigungskosten (z.B. Gerichts- und Anwaltskosten) vollständig freizustellen. </p></li></ol></div>

    <div><h2>Ausschluss </h2></div>
    <div></div></div><div><ol start="1"><li><p>Ein Verstoß gegen diese Teilnahmebedingungen berechtigt uns, den jeweiligen Teilnehmer von der Teilnahme auszuschließen. Dies gilt insbesondere, wenn der Teilnehmer falsche Angaben macht. Gleiches gilt bei Kommentaren, die als gewaltverherrlichend, anstößig, belästigend oder herabwürdigend angesehen werden können oder in sonstiger Weise gegen das gesellschaftliche Anstandsgefühl verstoßen. </p></li></ol></div><div></div><div><ol
    start="2"><li><p>Handelt es sich bei dem ausgeschlossenen Teilnehmer um einen bereits ausgelosten Gewinner, kann der Gewinn nachträglich aberkannt werden.  </p></li></ol></div><div></div></div><div><div></div>
  <div><h2>Vorzeitige Beendigung sowie Änderungen </h2></div>
  <div></div><div><ol start="1"><li><p>Wir behalten uns das Recht vor, das Gewinnspiel jederzeit, auch ohne Einhaltung von Fristen, ganz oder teilweise vorzeitig zu beenden oder in seinem Verlauf abzuändern. Dies gilt insbesondere auch dann, wenn es aus technischen (z.B. Computervirus, Manipulation von oder Fehler in Software/Hardware) oder rechtlichen Gründen nicht möglich ist, eine ordnungsgemäße Durchführung des Wettbewerbs zu garantieren.  </p></li></ol></div><div></div></div><div><div><ol
  start="2"><li><p>Erfüllungs- und Schadensersatzansprüche des Teilnehmers bei vorzeitiger Beendigung des Gewinnspiels sind ausgeschlossen.  </p></li></ol></div><div></div>
  <div><h2>Datenschutz </h2></div><div></div><div><p>Wir sind verantwortlich für die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten der Teilnehmer, sofern wir diese selbst verarbeiten. Wir werden die Angaben zur Person des Teilnehmers sowie seine sonstigen personenbezogenen Daten nur im Rahmen der gesetzlichen Bestimmungen des Datenschutzrechtes verwenden. Wir werden die Informationen nur insoweit speichern, verarbeiten und nutzen, soweit dies für die Durchführung des Gewinnspiels erforderlich ist bzw. eine Einwilligung des Teilnehmers vorliegt. Dies umfasst auch eine Verwendung zur Ausübung der eingeräumten Nutzungsrechte. Die Daten werden ausschließlich zur Durchführung des Gewinnspiels verwendet und anschließend gelöscht. </p></div></div><div><div><p>Der Teilnehmer kann jederzeit Auskunft über die zu seiner Person gespeicherten Daten verlangen. Im Übrigen gilt unsere Datenschutzerklärung entsprechend, die unter <a
  href="https://hochschulinitiative-deutschland.de/datenschutz" target="_blank" rel="noreferrer noopener">https://hochschulinitiative-deutschland.de/datenschutz</a> abrufbar ist. </p></div><div></div>
  <div><h2>Schlussbestimmungen </h2></div><div></div><div><ol start="1"><li><p>Sollten die Teilnahmebedingungen unwirksame Regelungen enthalten, bleibt die Wirksamkeit der Bedingungen im Übrigen unberührt.  </p></li></ol></div></div><div><div></div><div><ol
  start="2"><li><p>Es gilt deutsches Recht. Ein Rechtsweg zur Überprüfung des Auswahlverfahrens ist ausgeschlossen.</p></li></ol></div><div></div></div></div>
