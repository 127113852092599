<div class="page-content">
  <h2>Teilnahmebedingungen</h2>
  <p>Die Teilnahme an Gewinnspielen von der Uniwunder GmbH, nachfolgend Veranstalter genannt, ist
    kostenlos und richtet sich ausschließlich nach diesen Teilnahmebedingungen.</p>
  <p><b>Teilnahmeberechtigte</b><br>
    Teilnahmeberechtigt sind alle Studierenden.<br>
    Sollte ein Teilnehmer in seiner Geschäftsfähigkeit eingeschränkt sein, bedarf es der Einwilligung seines
    gesetzlichen Vertreters.<br>
    Nicht teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und Umsetzung des Gewinnspiels
    beteiligten Personen und Mitarbeiter des Betreibers sowie ihre Familienmitglieder. Zudem behält sich der
    Betreiber vor, nach eigenem Ermessen Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe
    vorliegen, beispielsweise<br>
    (a) bei Manipulationen im Zusammenhang mit Zugang zum oder Durchführung des Gewinnspiels, (b) bei Verstößen
    gegen diese Teilnahmebedingungen, (c) bei unlauterem Handeln oder (d) bei falschen oder irreführenden
    Angaben im Zusammenhang mit der Teilnahme an dem Gewinnspiel.</p>
  <p>
    <b>Veranstalter</b><br>
    Veranstalter ist die Uniwunder GmbH<br>
    Bergstraße 29<br>
    01069 Dresden<br>
    info@hochschulinitiative-deutschland.de
  </p>
  <div [innerHTML]="pageContent | safeHtml" *ngIf="pageContent"></div>
</div>
