import {Component, OnInit} from '@angular/core';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutService} from '../../seminar-checkout.service';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {SeminarCheckoutS3ProcessService} from '../seminar-checkout-s3-process.service';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {PublicMinimalCityData} from '../../../../generated/cronos/data';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-sem-checkout-s3-location',
  templateUrl: './sem-checkout-s3-zipCode.component.html',
  styleUrls: ['./sem-checkout-s3-zipCode.component.scss']
})
export class SemCheckoutS3ZipCodeComponent implements OnInit {

  page: PublicFormatPageData;
  zipCodeControl = new FormControl('', [Validators.required, this.validateZipCode()])
  zipCodeFound = false
  zipCodeSearched = false
  searchingZipCode = false

  matchingCities: PublicMinimalCityData[] = []
  zipCode: string = ""

  nextStepAttempted = new BehaviorSubject<boolean>(true)

  constructor(private router: Router,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private route: ActivatedRoute,
              private s3ProcessService: SeminarCheckoutS3ProcessService,
              private cronosStructureApi: PublicStructureResourceV2
  ) {

  }

  ngOnInit() {
    this.zipCodeControl.valueChanges.subscribe(code => this.evaluateZipCode(code))

    if (this.checkForForewarding()) {
      this.reportReached(true);
      this.next(true);
      return;
    }

    this.reportReached(false);
    window.scrollTo(0, 0);
    this.globalCheckoutService.forewardingEnabled = false;
  }

  /**
   * Checks if zip code follows the form (regex) of German zip codes and prevents input beyond 5 numbers.
   * If zip code conforms the database is queried for its existence
   */
  evaluateZipCode(code: string) {
    if (!this.zipCodeControl.hasError('notValidZipCode')) {
      this.findZipCodeInDatabase()
    } else {
      this.zipCodeSearched = false
      if (this.zipCodeControl.getError('notValidZipCode').exceedsLength) {
        let validCode = code.slice(0, -1)
        this.zipCodeControl.setValue(validCode)
      }
    }
  }


  findZipCodeInDatabase() {
    this.searchingZipCode = true
    this.zipCodeControl.markAsTouched()
    this.cronosStructureApi.doesZipCodeExist({zipCode: this.zipCodeControl.value}).then(res => {
      if (res.value) {
        this.zipCodeFound = true
        this.zipCodeControl.updateValueAndValidity({emitEvent: false})
        this.zipCode = this.zipCodeControl.value
        this.matchingCities = []
      } else {
        this.zipCodeFound = false
        this.zipCode = ""
      }
      this.searchingZipCode = false
      this.zipCodeSearched = true
    })
  }


  /**
   * Validates the input for German zip codes (accepts inclusively from 01001 to 99998)
   */
  validateZipCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const zipCodeRegEx: RegExp = new RegExp("^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$")
      if (!control.value) {
        return null
      }
      let isZipCode = zipCodeRegEx.test(control.value)
      let isTooLong = control.value.length > 5
      return isZipCode ? null : {
        notValidZipCode: {
          value: control.value,
          name: "input is not a valid German zip code",
          exceedsLength: isTooLong
        }
      }
    }
  }

  saveZipCodeAndContinue() {
    this.nextStepAttempted.next(true)
    if(!this.zipCodeControl.valid || !this.zipCodeFound) return
    this.cronosStructureApi.getNearestLocationFromZipCode({zipCode: this.zipCode}).then(res => {
      this.globalCheckoutService.updateZipCodeOnUser(this.zipCode)
      this.globalCheckoutService.updateLocationOnUser(res)
      this.next(false)
    })
  }

  continueWithNearestLocationToCity(cityId: number) {
    this.cronosStructureApi.getNearestLocationFromCityId({cityId: cityId}).then(res => {
      this.globalCheckoutService.updateLocationOnUser(res)
      this.next(false)
    })
  }

  reportReached(skip: boolean) {
    this.s3ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding() {
    if (!this.globalCheckoutService.forewardingEnabled) {
      return false;
    }
    //use case comes from a preselected seminar id that was passed via url parameter
    if (this.seminarCheckoutService.getSeminar()?.id) {
      return true;
    }
    //location has already been selected and stored, possibly in a prior checkout
    if (this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null) {
      return true;
    }
    return false;
  }

  next(foreward: boolean) {
    if (!foreward) {
      this.seminarCheckoutService.clearSelectedSeminar();
    }
    this.s3ProcessService.reportTrackingEvent('LocationSelected', {'skip': JSON.stringify(foreward)});
    this.router.navigate([this.s3ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s3/date')]);
  }


  displayCities(cities: PublicMinimalCityData[]){
    if(cities.length == 0){
      this.cronosStructureApi.getLocations().then(res=>{
        this.matchingCities = res;
      })
    } else {
      this.matchingCities = cities;
    }
  }

  protected readonly onabort = onabort;
}
