import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, OnlineLectureInfoData, OnlineLectureLessonData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.OnlineLectureAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class OnlineLectureAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/formats/onlineLecture/content/{code}',
        endpoint: 'cronos'
    })
    private _downloadOnlineLectureFormatContentMaster: IResourceMethodObservableStrict<void, {}, {code: string}, Blob>;
    downloadOnlineLectureFormatContentMaster(code: string): Promise<Blob> {
        return this._downloadOnlineLectureFormatContentMaster(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/onlineLecture/{code}',
        endpoint: 'cronos'
    })
    private _finishOnlineLecture: IResourceMethodObservableStrict<void, {}, {code: string}, void>;
    finishOnlineLecture(code: string): Promise<void> {
        return this._finishOnlineLecture(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{code}/appointment',
        endpoint: 'cronos'
    })
    private _getAppointmentRequestForOnlineLecture: IResourceMethodObservableStrict<void, {}, {code: string}, JsonWrappedValue<Date>>;
    getAppointmentRequestForOnlineLecture(code: string): Promise<JsonWrappedValue<Date>> {
        return this._getAppointmentRequestForOnlineLecture(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{formatId}/info',
        endpoint: 'cronos'
    })
    private _getOnlineLectureInfo: IResourceMethodObservableStrict<void, {code: string}, {formatId: number}, OnlineLectureInfoData>;
    getOnlineLectureInfo(formatId: number, requestParams: {code: string}): Promise<OnlineLectureInfoData> {
        return this._getOnlineLectureInfo(null, requestParams, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/onlineLecture/{code}/lesson/{id}',
        endpoint: 'cronos'
    })
    private _getOnlineLectureLessonByTokenAndId: IResourceMethodObservableStrict<void, {}, {code: string, id: number}, OnlineLectureLessonData>;
    getOnlineLectureLessonByTokenAndId(pathVariables: {code: string, id: number}): Promise<OnlineLectureLessonData> {
        return this._getOnlineLectureLessonByTokenAndId(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/onlineLecture/{code}/appointment',
        endpoint: 'cronos'
    })
    private _requestAppointmentForOnlineLecture: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {code: string}, void>;
    requestAppointmentForOnlineLecture(requestBody: JsonWrappedValue<string>, code: string): Promise<void> {
        return this._requestAppointmentForOnlineLecture(requestBody, null, {code: code}).toPromise()
    }

}
