import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FeedbackStarsComponent} from "./feedback-stars/feedback-stars.component";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  declarations: [FeedbackStarsComponent],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    CommonModule,
    BrowserModule,
  ],
  exports:[
    FeedbackStarsComponent
  ]
})
export class FeedbackStarsModule { }
