import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FeedbackDisplayData, FeedbackOverviewDisplayData, FeedBackOverviewData} from "../generated/cronos/data";
import {PromotionApiResourceV2} from "../generated/cronos/resources";
import {ActivatedRoute} from "@angular/router";
import {PublicOfferPageResource} from "../generated/cms/resources";
import {MetaService} from "../meta.service";

class FormatFeedbackGroup{
  constructor(
    public identifier:string,
    public formatIds: number[]
  ) {
  }
  data: FeedBackOverviewData;

  getTotalCount():number{
    if(!this.data) return
    return this.data.count1Stars + this.data.count2Stars + this.data.count3Stars + this.data.count4Stars + this.data.count5Stars
  }

  getPercentageForCount(count: number){
    if(!this.data) return
    let amount;
    switch(count){
      case 1: amount = this.data.count1Stars;break;
      case 2: amount = this.data.count2Stars;break;
      case 3: amount = this.data.count3Stars;break;
      case 4: amount = this.data.count4Stars;break;
      case 5: amount = this.data.count5Stars;break;
    }
    return (amount * 100 / this.getTotalCount()).toFixed(2)
  }

  getAverageRating(){
    if(!this.data) return
    return ((this.data.count1Stars + this.data.count2Stars * 2 + this.data.count3Stars *3 + this.data.count4Stars *4  + this.data.count5Stars * 5 )/ this.getTotalCount()).toFixed(1)
  }

}

type DisplayFormatName = 'global'|'steuer'|'thesis'|'excel'|'finance'

@Component({
  selector: 'app-feedback-overview',
  templateUrl: './feedback-overview.component.html',
  styleUrls: ['./feedback-overview.component.scss']
})
export class FeedbackOverviewComponent implements OnInit {

  @ViewChild("offerImage", {static: true}) offerImage: ElementRef;

  feedback: FeedbackDisplayData[] = [];
  initialized: boolean = false;
  feedbackLoading: boolean = false;
  pageSize: number = 10;
  page: number = 0;
  totalElements: number;
  selectedRating: number = null;


  feedbackGroups: FormatFeedbackGroup[] = [
    new FormatFeedbackGroup('steuer', [1938, 2214]),
    new FormatFeedbackGroup('thesis', [1950, 2215]),
    new FormatFeedbackGroup('excel', [1986, 2216]),
    new FormatFeedbackGroup('finance', [2237])
  ];

  selectedTopic: FormatFeedbackGroup;

  globalFeedback: FeedBackOverviewData = <FeedBackOverviewData>{}


  imageSrc: string = '';


  constructor(
    private promotionApiResourceV2: PromotionApiResourceV2,
    private route: ActivatedRoute,
    private offerPageResource: PublicOfferPageResource,
    private metaService: MetaService
  ) {
    let globalGroup = new FormatFeedbackGroup(
      "global",
      this.feedbackGroups.flatMap(g => g.formatIds)
    )

    this.feedbackGroups.push(globalGroup);

    this.selectedTopic = globalGroup;

    //Just for Preview
    route.queryParams.subscribe(params => {

      let previewId = params["preview"];
      if (previewId != null) {

        this.offerPageResource.getFormatSplitPreview(previewId).then(
          result => {
            // built offerImage for preview
            this.imageSrc = "https://hidcms.syntacton.de/images/containers/12629?height=270&width=250"
          }
        );
        return
      }
    })
  }

  ngOnInit(): void {
    this.loadFeedbackDataPage(this.page, null);
    this.loadAverageRatings();
    this.metaService.setMetaTag("description", "Was denken Studenten über die Hochschulinitiative? Das Seminar-Feedback zu Steuer, Excel, Thesis & Co. findest du hier!")
    this.metaService.setCustomTitleTag("Erfahrungen zu den Workshops | Hochschulinitiative Deutschland")
  }

  loadFeedbackDataPage(page: number, rating: number) {
    this.feedbackLoading = true;
    if (rating == this.selectedRating) {
      this.selectedRating = null;
      this.feedback = []
    } else if (rating != this.selectedRating) {
      this.selectedRating = rating;
      this.feedback = []
    }

    this.page = page


    this.promotionApiResourceV2.getRatingsByPromotionType({
      formatIds: this.selectedTopic.formatIds,
      rating: this.selectedRating,
      page: this.page,
      pageSize: this.pageSize
    }).then(
      result => {
        result.content.forEach(value => {
          this.feedback.push(value)
        })
        this.totalElements = result.totalElements;
        this.feedbackLoading = false;

      }
    )
  }

  loadAverageRatings() {

    this.globalFeedback = <FeedBackOverviewData>{}
    let loaded = 0;
    this.feedbackGroups.forEach(group => {
      this.promotionApiResourceV2.getRatingSummary({formatIds: group.formatIds}).then(res => {
        group.data = res;
        this.globalFeedback.count1Stars += res.count1Stars;
        this.globalFeedback.count2Stars += res.count2Stars;
        this.globalFeedback.count3Stars += res.count3Stars;
        this.globalFeedback.count4Stars += res.count4Stars;
        this.globalFeedback.count5Stars += res.count5Stars;
        loaded++;
        if(loaded == this.feedbackGroups.length -1){
          this.initialized = true;
        }
      });
      }
    )
  }

  selectTopic(identifier: string) {
    this.selectedTopic = this.feedbackGroups.find(e => e.identifier == identifier)

    this.feedback = []
    this.loadFeedbackDataPage(this.page, null);
  }

  getGroup(identifier: string):FormatFeedbackGroup{
    return this.feedbackGroups.find(e => e.identifier == identifier)
  }

}
