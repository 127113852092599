import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {
  BasicContactData,
  EnterPromotionContactData,
  PublicContactData,
  RegistrationInfoData
} from "../../../../generated/cronos/data";
import { SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {CheckoutContext, LocalStorageService} from "../../../../local-storage.service";
import {RegistrationInfoService} from "../../registration-info.service";

@Component({
  selector: 'app-seminar-student-not-present-thank-you-page',
  templateUrl: './seminar-student-not-present-thank-you-page.component.html',
  styleUrls: ['./seminar-student-not-present-thank-you-page.component.scss']
})
export class SeminarStudentNotPresentThankYouPageComponent implements OnInit {

  recommendedUpsellPageIds: number[] = [];
  offerPageId: number;
  token: string = '';

  registrationInfo: RegistrationInfoData = <RegistrationInfoData>{};
  registrationInfoLoading: boolean = true;


  constructor(
    private route: ActivatedRoute,
    private offerPageResource: PublicOfferPageResource,
    private router: Router,
    private localStorageService: LocalStorageService,
    private publicOfferPageResource: PublicOfferPageResource,
    private seminarProcessAPIResourceV2: SeminarProcessAPIResourceV2,
    private registrationInfoService: RegistrationInfoService
  ) {
  }

  ngOnInit() {

    this.getTokenInformation();
  }

  getTokenInformation() {
    this.registrationInfoLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.registrationInfo = result
      this.registrationInfoLoading = false;

      if (this.registrationInfo.promotionData) this.getRecommendedOfferPageIds();
    })
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  newTermin() {
    let contact: BasicContactData = this.registrationInfo.contactData;
    let enterPromotionContactData: EnterPromotionContactData = {
      age: null,
      birthDate: contact.birthDate,
      email: contact.email,
      firstName: contact.firstName,
      gender: contact.gender,
      lastName: contact.lastName,
      locationId: contact.locationId,
      zipCode: null,
      phoneNumber: contact.phoneNumber,
      semester: contact.semester,
      studyAreaId: null,
      studyField: null,
      subject: contact.subject,
      studyEndYear: contact.studyEndYear,
      universityName: null,
      privacyConsentGiven: contact.privacyConsentGiven,
      talentagentContactId: null
    };
    let context: CheckoutContext = {
      formatId: null,
      promotion: null,
      contextPage:null,
      location: this.registrationInfo.locationData
    };

    this.localStorageService.updateCheckoutUserData(enterPromotionContactData);
    this.localStorageService.updateCheckoutContext(context);

    this.publicOfferPageResource.getFormatPagePreviewDataBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(result => {
      this.router.navigate(['/angebote/' + result.routingUrl], {queryParams: {instantCheckout: true}})
    })

  }
}
