<div class="login-menu-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <a (click)="$event.stopPropagation();$event.preventDefault();">

    <div *ngIf="token == null">
      <input class="login-input"  placeholder="Email" [formControl]="email"/>
      <button class="signup-button" (click)="request()">Anfragen</button>
    </div>

    <div *ngIf="token != null">
      <hid-loading-spinner [loadingBool]="validatingToken">
        <div *ngIf="tokenConfirmed">
          <input class="login-input"  placeholder="Passwort" [formControl]="password" type="password"/>
          <button class="signup-button" (click)="reset()">Zurücksetzen</button>
        </div>
        <div *ngIf="tokenConfirmed == false" >
          Dein Passwort-Zurücksetzen Link ist leider abgelaufen.
        </div>
      </hid-loading-spinner>

    </div>
  </a>
</div>


<!--
{{token}}
-->
