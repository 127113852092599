import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutGatewayComponent} from './seminar-checkout-gateway/seminar-checkout-gateway.component';
import {SemCheckoutS2Module} from './seminar-checkout-s2/sem-checkout-s2.module';
import {RouterModule, Routes} from '@angular/router';
import {ClickOutsideDirective} from './click-outside.directive';
import {SemCheckoutS3Module} from './seminar-checkout-s3/sem-checkout-s3.module';
import {SemCheckoutS3aModule} from './seminar-checkout-s3a/sem-checkout-s3a.module';


const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/seminars/g', component: SeminarCheckoutGatewayComponent}];

@NgModule({
  declarations: [
    SeminarCheckoutGatewayComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    SemCheckoutS2Module,
    SemCheckoutS3Module,
    SemCheckoutS3aModule,
    RouterModule.forChild(appRoutes)
  ]
})
export class SeminarCheckoutModule { }
