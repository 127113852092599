import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import Bugsnag from '@bugsnag/js';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {NgbDropdownModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {BlogModule} from './blog/blog.module';
import {StaticModule} from './static/static.module';
import {BundleModule} from './bundle/bundle.module';
import {HomeModule} from './home/home.module';
import {OfferPageModule} from './offer-page/offer-page.module';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {StudentSessionResource} from './generated/cms/resources';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestInterceptor} from './request.interceptor';
import {ThankYouModule} from './thank-you/thank-you.module';
import {FacebookModule} from 'ngx-facebook';
import {ActionLinkModule} from './actionLinks/action-link/action-link.module';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {Angulartics2Module} from 'angulartics2';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {RequestErrorInterceptorService} from './request-error-interceptor.service';
import {ErrorModule} from './error/error.module';
import {OfferpageDeactivatedModule} from './offerpage-deactivated/offerpage-deactivated.module';
import {environment} from '../environments/environment';
import {OnlineLectureModule} from './online-lecture/online-lecture.module';
import {HidInteractionsModule} from './hid-interactions/hid-interactions.module';
import {UserOfflineDialogComponent} from './inno-utils/dialogs/user-offline-dialog/user-offline-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {FeedbackOverviewComponent} from './feedback-overview/feedback-overview.component';
import {FeedbackStarsModule} from './feedback-stars/feedback-stars.module';
import {PromotionReminderComponent} from './promotion-reminder/promotion-reminder.component';
import {WebinarReminderPopupComponent} from './promotion-reminder/webinar-reminder-popup/webinar-reminder-popup.component';
import {NgxLocalStorageModule} from 'ngx-localstorage';
import {MlpServiceFormStudentModule} from './mlp-service-form-student/mlp-service-form-student.module';
import {MlpServiceFormConsultantModule} from './mlp-service-form-consultant/mlp-service-form-consultant.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {AccountModule} from './account/account.module';
import {MatMenuModule} from '@angular/material/menu';
import {DiscountsModule} from './discounts/discounts.module';
import {NotificationResource, QuestionResource} from './generated/cronos/resources';
import {MatSelectModule} from '@angular/material/select';
import {GlobalModule} from './global/global.module';
import {NgxPiwikProModule, NgxPiwikProRouterModule} from '@piwikpro/ngx-piwik-pro';
import {LinkHubModule} from './link-hub/link-hub.module';
import {GDPRConsentModule} from './gdprconsent/gdprconsent.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    FeedbackOverviewComponent,
    PromotionReminderComponent,
    WebinarReminderPopupComponent,

  ],
  imports: [
    BrowserModule,
    FlexModule,
    NgbModalModule,
    HidInteractionsModule,
    FlexLayoutModule,
    NgbDropdownModule,
    HttpClientModule,
    BlogModule,
    AccountModule,
    StaticModule,
    BundleModule,
    ThankYouModule,
    DiscountsModule,
    ErrorModule,
    HomeModule,
    ActionLinkModule,
    OfferPageModule,
    OfferpageDeactivatedModule,
    NgxPiwikProModule.forRoot(environment.piwikPageId, environment.piwikContainerUrl),
    NgxPiwikProRouterModule,
    MlpServiceFormStudentModule,
    MlpServiceFormConsultantModule,
    MatMenuModule,
    MatSelectModule,
    GlobalModule,
    OnlineLectureModule,
    GDPRConsentModule,
    NgxMatSelectSearchModule,
    FacebookModule.forRoot(),
    ResourceModule.forRoot(),
    MatButtonModule,
    Angulartics2Module.forRoot(),
    FeedbackStarsModule,
    NgxLocalStorageModule.forRoot({allowNull: false}),
    LinkHubModule,
    AppRoutingModule, //Needs to be below other modules with app routes
    //CodeInputModule - Import for better Code Input (UI)
  ],
  entryComponents: [
    UserOfflineDialogComponent,
    WebinarReminderPopupComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {provide: 'googleTagManagerId', useValue: environment.gtmId},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorInterceptorService,
      multi: true,
    }, {
      provide: 'env', // you can also use InjectionToken
      useValue: environment
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    StudentSessionResource,
    QuestionResource,
    NotificationResource
  ],
  exports: [],

  bootstrap: [AppComponent]
})
export class AppModule {
}

export function errorHandlerFactory() {
  const client = Bugsnag.start({
    apiKey: 'e53d92c364bf5c4ef7a666d69a4d5292',
    appVersion: environment.appVersion,
    releaseStage: environment.production ? 'production' : 'development'
  });
  return new BugsnagErrorHandler(client);
}
