import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {
  OnlineWorkshopDropdownItemData,
  PublicOfferBundleData,
  PublicOfferBundleMinimalData,
  PublicOfferBundleWithPreviewsData
} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicBundleController
 */

@Injectable()
@ResourceParams({})
export class PublicBundleResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/id/{bundleId}',
        endpoint: 'cms'
    })
    private _getBundleById: IResourceMethodObservableStrict<void, {}, {bundleId: number}, PublicOfferBundleData>;
    getBundleById(bundleId: number): Promise<PublicOfferBundleData> {
        return this._getBundleById(null, null, {bundleId: bundleId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/{routingUrl}',
        endpoint: 'cms'
    })
    private _getBundleByRoutingUrl: IResourceMethodObservableStrict<void, {}, {routingUrl: string}, PublicOfferBundleData>;
    getBundleByRoutingUrl(routingUrl: string): Promise<PublicOfferBundleData> {
        return this._getBundleByRoutingUrl(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/{routingUrl}/withPreviews',
        endpoint: 'cms'
    })
    private _getBundleWithPreviews: IResourceMethodObservableStrict<void, {}, {routingUrl: string}, PublicOfferBundleWithPreviewsData>;
    getBundleWithPreviews(routingUrl: string): Promise<PublicOfferBundleWithPreviewsData> {
        return this._getBundleWithPreviews(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles',
        endpoint: 'cms'
    })
    private _getBundles: IResourceMethodObservableStrict<void, {}, {}, PublicOfferBundleData[]>;
    getBundles(): Promise<PublicOfferBundleData[]> {
        return this._getBundles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundlesOffersDropdown',
        endpoint: 'cms'
    })
    private _getBundlesForOffersDropdown: IResourceMethodObservableStrict<void, {}, {}, PublicOfferBundleMinimalData[]>;
    getBundlesForOffersDropdown(): Promise<PublicOfferBundleMinimalData[]> {
        return this._getBundlesForOffersDropdown(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bundles/onlineWorkshopOffersDropdown',
        endpoint: 'cms'
    })
    private _getOffersForOnlineWorkshopDropdown: IResourceMethodObservableStrict<void, {}, {}, OnlineWorkshopDropdownItemData[]>;
    getOffersForOnlineWorkshopDropdown(): Promise<OnlineWorkshopDropdownItemData[]> {
        return this._getOffersForOnlineWorkshopDropdown(null, null, null).toPromise()
    }

}
