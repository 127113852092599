import {Component, OnInit} from '@angular/core';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {LocalStorageService} from '../../../../local-storage.service';
import {PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutService} from '../../seminar-checkout.service';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {SeminarCheckoutS2ProcessService} from '../seminar-checkout-s2-process.service';

@Component({
  selector: 'app-sem-checkout-s2-location',
  templateUrl: './sem-checkout-s2-location.component.html',
  styleUrls: ['./sem-checkout-s2-location.component.scss']
})
export class SemCheckoutS2LocationComponent implements OnInit {


  page: PublicFormatPageData;

  constructor(private cronosStrcutreApi: PublicStructureResourceV2,
              private router: Router,
              private localStorageService: LocalStorageService,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private route: ActivatedRoute,
              private s2ProcessService: SeminarCheckoutS2ProcessService
  ) {

  }

  ngOnInit() {

    if (this.checkForForewarding()) {
      this.reportReached(true);
      this.next(true);
      return;
    }

    this.reportReached(false);
    window.scrollTo(0, 0);
    this.globalCheckoutService.forewardingEnabled = false;
  }

  reportReached(skip: boolean) {
    this.s2ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding() {
    if (!this.globalCheckoutService.forewardingEnabled) {
      return false;
    }
    //use case comes from a preselected seminar id that was passed via url parameter
    if (this.seminarCheckoutService.getSeminar() && this.seminarCheckoutService.getSeminar().id) {
      return true;
    }
    //location has already been selected and stored, possibly in a prior checkout
    if (this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null) {
      return true;
    }
    return false;
  }

  next(foreward: boolean) {
    if (!foreward) {
      this.seminarCheckoutService.clearSelectedSeminar();
    }
    this.s2ProcessService.reportTrackingEvent('LocationSelected', {'skip': JSON.stringify(foreward)});
    this.router.navigate([this.s2ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s2/date')]);
  }


}
