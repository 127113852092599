<!--The content below is only a placeholder and can be replaced.-->
<!--<div id="master-wrapper" [ngClass]="{'no-footer': router.url.indexOf('angebote') > -1}">-->


<div id="master-wrapper"
     [ngClass]="{'no-footer': ((router.url.indexOf('checkout') > -1 || router.url.indexOf('angebote') > -1) && mobileQuery.matches) || (router.url.split('?')[0].includes('/linkhub/')) ,
      'no-scroll': navToggle || noScroll
     }">

  <promotion-reminder class="promotion-reminder" id="webinar-website-reminder-container"></promotion-reminder>
  <div id="mobile-nav" [ngClass]="{'show':navToggle && mediaService.getMaxWidthQuery(1080).matches}">
    <div fxLayout="row" fxLayoutAlign="start center" class="w-100" style=" margin-bottom: 30px; height: 70px;">
      <button class="hid-icon-button inverse mobile-cropped-header-item" (click)="navToggle = false"><i class="close-icon fa fa-times"></i></button>
      <img  *ngIf="!router.url.includes('profile') && !mediaService.getMaxWidthQuery(600).matches" src="../assets/img/brandlogo.png">
      <img class="nav-logo" *ngIf="router.url.includes('profile') && !mediaService.getMaxWidthQuery(600).matches" src="../assets/img/uniwunder-logo.png">
      <img class="nav-logo" *ngIf="mediaService.getMaxWidthQuery(600).matches" src="../assets/img/brandlogo.png">
    </div>
    <div class="nav-ele">
      <a class="nav-link ita_main-nav_career"
         target="_blank" (click)="utilService.safeRouteToUrl('angebote/karriereplattform?utm_source=hidpage&utm_medium=navbar')">
        <!--*ngIf="!authService.isAuthenticated()"-->
        <span id="career-new-mobile">NEU</span>
        Jobeinstieg
      </a>
    </div>

    <div class="nav-ele" fxLayout="column" fxLayoutAlign="center center" *ngIf="onlineSeminarBundle">

      <div fxLayout="row" fxLayoutAlign="center center">
        <a class="nav-link ita_main-nav_offers" (click)="showOnlineBundleSpoiler = !showOnlineBundleSpoiler">
          <span id="deals-new-mobile">BELIEBT</span>
          <span [innerHTML]="onlineSeminarBundle.title | safeHtml"></span>
        </a>
        <i class="mdi mdi-chevron-down" [ngClass]="{'flip-down': showOnlineBundleSpoiler, 'flip-up': !showOnlineBundleSpoiler}"></i>
      </div>
      <div id="online-bundles-spoiler" [ngClass]="{'show': showOnlineBundleSpoiler}">
        <a (click)="utilService.safeRouteToUrl('/angebote/'+ offer.routingUrl)"
           class="nav-link ita_main-nav_bundle-{{offer.routingUrl}}"
           *ngFor="let offer of onlineSeminars; let i = index">{{offer.displayTitleForNav}}</a>
        <a routerLink="/feedbacks"  class="nav-link ita_main-nav_feedbacks">
          Erfahrungen
        </a>
      </div>
    </div>


    <div class="nav-ele" fxLayout="column" fxLayoutAlign="center center">


      <div fxLayout="row" fxLayoutAlign="center center">
        <a class="nav-link ita_main-nav_offers" (click)="showBundlesSpoiler = !showBundlesSpoiler">
          Themen
        </a>
        <i class="mdi mdi-chevron-down" [ngClass]="{'flip-down': showBundlesSpoiler, 'flip-up': !showBundlesSpoiler}"></i>
      </div>

      <div id="bundles-spoiler" [ngClass]="{'show': showBundlesSpoiler}">
        <a class="nav-link ita_main-nav_home"
           routerLink="/home"  (click)="navToggle=false">Alle</a>
        <a class="nav-link ita_main-nav_bundle-{{bundle.routingUrl}}" *ngFor="let bundle of bundles"
           (click)="utilService.safeRouteToUrl('/kategorien/' + bundle.routingUrl)" [innerHTML]="bundle.title | safeHtml"></a>

      </div>
    </div>
    <div class="nav-ele">
      <a class="nav-link ita_main-nav_blog"  routerLink="/blog"  (click)="navToggle=false">
        Blog
      </a>
    </div>

    <div class="nav-ele">
      <a class="nav-link ita_main-nav_events" target="_blank"
         href="https://uniwunder.com/jobs?utm_source=hidpage&utm_medium=navbar&utm-campaign=navbarjobs">
        Karriere bei Uniwunder
      </a>
    </div>


    <div class="nav-ele">
      <a class="nav-link ita_main-nav_about" routerLink="/ueber-uns"  (click)="navToggle=false">
        Über uns
      </a>
    </div>

  </div>
  <div
    *ngIf="!((router.url.toLowerCase().indexOf('checkout') > -1 && mobileQuery.matches) || (router.url.split('?')[0].includes('/linkhub/')))"
    id="header-bar">
    <div fxLayout="row" fxLayoutAlign="space-around center" style="height: 100%; width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center" class="pointer">
          <button *ngIf="mediaService.getMaxWidthQuery(1080).matches" class="mobile-cropped-header-item" id="menu-button" (click)="navToggle = !navToggle"><i class="fa fa-bars"></i></button>
          <img  *ngIf="!mediaService.getMaxWidthQuery(600).matches" routerLink="/home" id="brandlogo" src="../assets/img/brandlogo.png">
          <!--img class="nav-logo" *ngIf="router.url.includes('deals') && !mediaService.getMaxWidthQuery(600).matches" src="../assets/img/dealwunder-logo.png"-->
          <!--img class="nav-logo"  *ngIf="router.url.includes('profile') && !mediaService.getMaxWidthQuery(600).matches" src="../assets/img/uniwunder-logo.png"-->
          <img class="nav-logo" routerLink="/home" *ngIf="mediaService.getMaxWidthQuery(600).matches" src="../assets/img/brandlogo.png">
<!--          <img src="../assets/img/sloganbanner.png" class="show-lg-screen-only" style="height: 100%" fxHide.lt-md>-->
      </div>
<!--
            <account-sidenav style="margin-right: 10px;" *ngIf="mediaService.getMaxWidthQuery(1080).matches"></account-sidenav>
      -->

      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!mediaService.getMaxWidthQuery(1080).matches" id="desktop-nav">
        <a class="nav-link ita_main-nav_career" (click)="utilService.safeRouteToUrl('angebote/karriereplattform?utm_source=hidpage&utm_medium=navbar')"
           target="_blank"
           style="position: relative;">
          <span id="career-new">NEU</span>
          Jobeinstieg
        </a>

        <div ngbDropdown class="d-inline-block nav-link" *ngIf="!loadingBundles">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button class="btn nav-link btn-outline-primary ita_main-nav_offers"
                    id="online-offer-dropdown"
                    ngbDropdownToggle>
              <span id="popular-tag">BELIEBT</span>
              <span [innerHTML]="onlineSeminarBundle.title | safeHtml"> </span> <i class="mdi mdi-chevron-down"></i></button>


          </div>
          <div ngbDropdownMenu class="drop-down-desktop">
            <a
               (click)="utilService.safeRouteToUrl('/angebote/'+ offer.routingUrl)"
               class="dropdown-item ita_main-nav_bundle-{{offer.routingUrl}}"
               *ngFor="let offer of onlineSeminars; let i = index">{{offer.displayTitleForNav}}</a>
            <a routerLink="/feedbacks"  class="dropdown-item ita_main-nav_feedbacks">
              Erfahrungen
            </a>
          </div>
        </div>



        <div ngbDropdown class="d-inline-block nav-link">
          <button class="btn nav-link btn-outline-primary" id="offer-dropdown" ngbDropdownToggle>
            <span>Themen<i class="mdi mdi-chevron-down"></i></span>
          </button>
          <div ngbDropdownMenu class="drop-down-desktop">
            <a routerLink="/home"   class="dropdown-item ita_main-nav_bundle-all">Alle</a>
            <a (click)="utilService.safeRouteToUrl('/kategorien/' + bundle.routingUrl)"
               class="dropdown-item ita_main-nav_bundle-{{bundle.routingUrl}}"
               *ngFor="let bundle of bundles" [innerHTML]="bundle.title | safeHtml"></a>
          </div>
        </div>



        <a routerLink="/blog"  class="nav-link ita_main-nav_blog">
          Blog
        </a>

        <a href="https://uniwunder.com/jobs?utm_source=hidpage&utm_medium=navbar&utm-campaign=navbarjobs"
           target="_blank"
           class="nav-link ita_main-nav_events">
          Karriere bei Uniwunder
        </a>

      </div>

      <account-sidenav ></account-sidenav>

    </div>
  </div>

  <div id="main-content" >
    <router-outlet></router-outlet>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-evenly center" id="footer-regular"  >
    <div style="max-width: 860px;" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-between center">
      <div fxLayout="column" fxLayoutAlign.lt-md="space-between center" fxLayoutAlign="start center" fxFlex="80" style="height: 100%" class="about">
        <h2 class="text-primary" style="margin-bottom: 15px">Über die Hochschulinitiative Deutschland</h2>
        <div fxLayout="row" fxLayoutAlign.lt-md="space-between center" style="height: 100%" fxLayoutAlign="space-between center" fxLayoutGap="15px">
          <img fxHide.lt-md src="../assets/img/brandlogo.png" style="height: 50px">
          <div>Wir von Uniwunder haben es uns zur Mission gemacht, gemeinsam mit dir dein volles Erfolgspotential auszuschöpfen.
            Deswegen findest du bei der Hochschulinitiative Deutschland für dein Studium nützliche Seminare, tolle Tipps und weitere Angebote!
            Du bist Absolvent oder stehst kurz vor dem Abschluss? Mit unserer Karriereplattform Talentagent unterstützen wir dich beim perfekten Karrierestart!
          </div>
        </div>
        <h2 style="margin-top: 10px;" class="sm-header">Du findest uns auch auf:</h2>
        <div fxLayout="row" fxLayoutAlign="space-evenly" class="width-100 icon-wrapper">
          <a href="https://www.facebook.com/hochschulinitiative/" target="_blank"><i class="fab fa-facebook text-primary"></i></a>
          <a href="https://www.instagram.com/hochschulinitiativedeutschland/" target="_blank"><i class="fab fa-instagram text-primary"></i></a>
          <a href="https://www.youtube.com/hochschulinitiativedeutschland" target="_blank"><i class="fab fa-youtube text-primary"></i></a>
          <a href="https://www.pinterest.de/hochschulinitiativedeutschland/" target="_blank"><i class="fab fa-pinterest text-primary"></i></a>
          <a href="https://www.linkedin.com/company/uniwunder" target="_blank"><i class="fab fa-linkedin text-primary"></i></a>
          <a href="https://www.xing.com/pages/uniwunder" target="_blank"><i class="fab fa-xing text-primary"></i></a>
        </div>
      </div>

      <div fxLayout="column" fxFlex="20" fxFlex.lt-md="10" class="footer-right width-100">

        <div fxLayout="column" fxLayoutAlign="space-evenly start" fxLayout.lt-md="row" fxLayoutAlign.lt-md="space-between center" class="height-100 width-100">
          <a routerLink="/home" class="link-classic" fxHide.lt-md>Startseite</a>
          <a class="link-classic" (click)="utilService.safeRouteToUrl('angebote/karriereplattform?utm_source=hidpage&utm_medium=footer')" fxHide.lt-md>Talentagent</a>
          <a href="https://uniwunder.com/jobs/" class="link-classic"fxHide.lt-md>Jobs</a>
          <a href="https://uniwunder.com" target="_blank" class="link-classic" fxHide.lt-md>Über uns</a>
          <a routerLink="/datenschutz" class="link-classic">Datenschutz</a>
          <a routerLink="/impressum" class="link-classic">Impressum</a>
          <a routerLink="/support-info" class="link-classic">Support</a>
          <div><i class="fa fa-copyright"></i> 2020 Uniwunder</div>
        </div>
      </div>
    </div>
  </div>


</div>
