import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {LocalStorageService} from "../local-storage.service";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AccountAuthGuardService implements CanActivate{

  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService
              ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if((!this.authService.isAuthenticated() && route.url.toString().includes('account')) ) {
      this.router.navigateByUrl('/home')
      return false
    }
    // else if (!this.authService.isAuthenticated() && route.url.toString().includes('deals')){
    //   this.router.navigateByUrl('/angebote/dealwunder')
    //   return false
    // }
    else return true
  }
}
