
<div class="actionlink-page-wrapper">
  <div class="actionlink-main-box">
<!-- Start of process -->
<div *ngIf="!(handoutDownloaded || emailSend) && registrationInfo.promotionData"
      class="ita-format-{{registrationInfo.promotionData.formatId}}" fxLayout="column"
     fxLayoutAlign="start center">
  <div id="headline-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <i class="fas fa-file-download icon"></i>
    <div *ngIf="registrationInfo.promotionData.formatId != 2324">Materialien zu deinem Seminar</div>
    <div *ngIf="registrationInfo.promotionData.formatId == 2324">Dein E-Book "Last Minute Plan für deine Prüfungsphase"</div>
  </div>

  <div class="explanation" *ngIf="registrationInfo.promotionData.formatId != 2324">
    Hier kannst du deine Unterlagen zu dem Seminar downloaden oder lass dir die Dokumente an deine E-Mail weiterleiten.
  </div>
  <div class="explanation" *ngIf="registrationInfo.promotionData.formatId == 2324">
    Hier kannst du dein E-Book downloaden oder an deine E-Mail weiterleiten lassen.
  </div>

  <div *ngIf="handoutDownloaded" class="checkAction">Handout erfolgreich heruntergeladen.</div>
  <div *ngIf="emailSend" class="checkAction">E-Mail erfolgreich gesendet.</div>

  <div id="download-box" fxLayout="row" fxLayoutAlign="start center">

    <div id="download-button">
      <button class="hid-button primary button ita-download-handout-btn" fxLayout="column" fxLayoutAlign="center center"
              (click)="donwloadHandout()">
        <div *ngIf="registrationInfo.promotionData.formatId != 2324"> Handout runterladen</div>
        <div *ngIf="registrationInfo.promotionData.formatId == 2324"> E-Book runterladen</div>
        <i class="fas fa-file-download icon"></i>
      </button>

    </div>

    <div id="email-button">
      <button class="hid-button secondary button ita-send-handout-email-btn" fxLayout="column" fxLayoutAlign="center center"
              (click)="sendHandoutToEmail()">
        <div>an E-Mail weiterleiten</div>
        <i class="fas fa-envelope icon"></i>
      </button>


    </div>

  </div>
</div>

<!-- User finished downloading -->

<div *ngIf=" (handoutDownloaded || emailSend)" id="donwload-handout-wrapper"
     fxLayout="column" fxLayoutAlign="start center">
  <div id="headline-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <i style="color: #00c700" class="fas fa-file-download icon"></i>
    <div>Materialien erfolgreich heruntergeladen.</div>
  </div>

  <div *ngIf="handoutDownloaded" class="checkAction">Handout erfolgreich heruntergeladen.</div>
  <div *ngIf="emailSend" class="checkAction">E-Mail erfolgreich gesendet.</div>

  <div class="explanation">
    Viel Spaß beim Seminar! Wir freuen uns später über dein Feedback.
  </div>

</div>
  </div>
</div>

<!-- Recommendations in case seminar is already over -->

<div id="upsell" fxLayout="column" fxLayoutAlign="start center"
     *ngIf=" (handoutDownloaded || emailSend) && afterSeminar">
  <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0">

    <h1>Das könnte dich auch interessieren:</h1>
    <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
      <app-offer-page-panel
        [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
        [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
      </app-offer-page-panel>
    </div>
  </div>

  <div *ngIf="recommendedUpsellPageIds.length == 0" fxLayout="column" fxLayoutAlign="center center" id="to-main-page">
    <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich!</div>
    <img src="../../../../../assets/img/seminars2.jpg">
    <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
  </div>
</div>
