import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PublicContactData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.ContactApiControllerV2
 */

@Injectable()
@ResourceParams({})
export class ContactApiResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/contact/authentication',
        endpoint: 'cronos'
    })
    private _getContactAuthenticationInformationByToken: IResourceMethodObservableStrict<void, {code: string}, {}, JsonWrappedValue<string>>;
    getContactAuthenticationInformationByToken(requestParams: {code: string}): Promise<JsonWrappedValue<string>> {
        return this._getContactAuthenticationInformationByToken(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/contact/{contactDataAccessToken}',
        endpoint: 'cronos'
    })
    private _getContactDataByAccessToken: IResourceMethodObservableStrict<void, {}, {contactDataAccessToken: string}, PublicContactData>;
    getContactDataByAccessToken(contactDataAccessToken: string): Promise<PublicContactData> {
        return this._getContactDataByAccessToken(null, null, {contactDataAccessToken: contactDataAccessToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/contact/phoneNumber/verify/sms',
        endpoint: 'cronos'
    })
    private _sendAuthenticationSmsToVerifyContactPhoneNumber: IResourceMethodObservableStrict<void, {code: string, phoneNumber: string}, {}, void>;
    sendAuthenticationSmsToVerifyContactPhoneNumber(requestParams: {code: string, phoneNumber: string}): Promise<void> {
        return this._sendAuthenticationSmsToVerifyContactPhoneNumber(null, requestParams, null).toPromise()
    }

}
