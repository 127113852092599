import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutS2ProcessService} from '../seminar-checkout-s2-process.service';

@Component({
  selector: 'app-seminar-checkout-s2-entry',
  templateUrl: './seminar-checkout-s2-entry.component.html',
  styleUrls: ['./seminar-checkout-s2-entry.component.sass']
})
export class SeminarCheckoutS2EntryComponent implements OnInit {


  constructor(private router: Router, private route: ActivatedRoute,
              private s2ProcessService: SeminarCheckoutS2ProcessService) {
  }


  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.s2ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s2/location')]);
  }

  returnToOfferPage(){
  }


}
