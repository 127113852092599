import {Component, OnInit} from '@angular/core';
import {ConditionsOfParticipationData} from "../../generated/cronos/data";
import {LegalAPIResourceV2} from "../../generated/cronos/resources";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-conditions-of-participation',
  templateUrl: './conditions-of-participation.component.html',
  styleUrls: ['./conditions-of-participation.component.scss']
})
export class ConditionsOfParticipationComponent implements OnInit {

  formatId: number;
  pageContent: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private legalAPIResourceV2: LegalAPIResourceV2
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.formatId = params['identifier'];

      this.legalAPIResourceV2.getCurrentConditionsOfParticipation(this.formatId).then(result => {
        this.pageContent = result.value
      })
    });
  }
}
