import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OfferPagePanelComponent} from "./offer-page-panel/offer-page-panel.component";
import {PublicOfferPageResource} from "../generated/cms/resources";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {OfferPageModule} from "../offer-page/offer-page.module";
import {FeedbackStarsModule} from "../feedback-stars/feedback-stars.module";
import {AngularFittextModule} from "angular-fittext"
import {GlobalModule} from "../global/global.module";
import {OfferLastPagePanelComponent} from "./offer-last-page-panel/offer-last-page-panel.component";

@NgModule({
  declarations: [
    OfferPagePanelComponent,
    OfferLastPagePanelComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularFittextModule,
    FlexModule,
    FlexLayoutModule,
    FeedbackStarsModule,
    GlobalModule
  ],
  providers: [
    PublicOfferPageResource
  ],

  exports: [
    OfferPagePanelComponent,
    OfferLastPagePanelComponent
  ]
})
export class OfferPagePanelModule { }
