import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutS3ProcessService} from '../seminar-checkout-s3-process.service';

@Component({
  selector: 'app-seminar-checkout-s3-entry',
  templateUrl: './seminar-checkout-s3-entry.component.html',
  styleUrls: ['./seminar-checkout-s3-entry.component.sass']
})
export class SeminarCheckoutS3EntryComponent implements OnInit {


  constructor(private router: Router, private route: ActivatedRoute,
              private s3ProcessService: SeminarCheckoutS3ProcessService) {
  }


  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.s3ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s3/location')]);
  }

  returnToOfferPage(){
  }


}
