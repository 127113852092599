<div class="actionlink-page-wrapper" fxLayout="column" fxLayoutAlign="center center">

<div class="actionlink-main-box">
  <div *ngIf="!feedbackWasPositive"><h1><i class="mdi mdi-check-circle-outline" style="font-size: 64px"></i><br>Danke für dein Feedback!</h1></div>
  <div *ngIf="feedbackWasPositive && registrationInfo.promotionData.formatId != 2324"><h1><i class="mdi mdi-check-circle-outline" style="font-size: 64px"></i><br>Danke für dein Feedback! Wir haben dir deine Seminarunterlagen per Mail geschickt</h1>
  </div>
  <div *ngIf="registrationInfo.promotionData.formatId == 2324 && feedbackWasPositive"><h1><i class="mdi mdi-check-circle-outline" style="font-size: 64px"></i><br>Danke für dein Feedback! Wir haben dir dein E-Book per Mail geschickt</h1>
  </div>


  <!-- trust pilot feedback  -->
  <div *ngIf="feedbackWasPositive" class="misc-box">
    Wir würden uns riesig freuen, wenn du deine Bewertung auch bei Trustpilot teilst!<br><br>
    <a class="trustpilot-cta " [href]="goToTrustPilotReview()" fxLayout="row" fxLayoutAlign="center center">
      <img ngSrc="assets/img/trustpilot-logo.png" height="20" width="20">
      <span>bewerten</span>
    </a>
  </div>

  <!-- route to download course materials -->
  <div *ngIf="registrationInfo.contactData != undefined && !feedbackWasPositive" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div id="download-button">
      <button class="hid-button primary button ita-download-handout-btn" fxLayout="column" fxLayoutAlign="center center"
              (click)="navigateToHandout()">
        <div *ngIf="registrationInfo.promotionData.formatId != 2324"> Hier Unterlagen downloaden <i class="fas fa-file-download icon"></i> </div>
        <div *ngIf="registrationInfo.promotionData.formatId == 2324"> Hier E-Book downloaden <i class="fas fa-file-download icon"></i> </div>
      </button>
    </div>
    <div class="misc-box">
      <strong>{{registrationInfo.contactData.firstName}}, wir haben da übrigens noch was für dich 🤫</strong><br>
      Sichere dir jetzt dein Allround-Präsentations-Paket inkl. PowerPoint Vorlage, Checkliste und Tipps für die perfekte Präsi in der Uni!<br>
      <a href="/angebote/kostenlose-praesentationsvorlage"><strong>Zu den Präsentationsvorlagen</strong></a>
    </div>
  </div>

  <!--Zertifikat-download -->
  <div *ngIf="permissionToDownloadCertificate" fxLayout="row"  fxLayout.xs="column" fxLayoutAlign="space-between center" class="block">
    <div>Lade dein persönliches Zertifikat für die Veranstaltung herunter!</div>
    <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="start center" style="padding: 0 8px">
      <hid-loading-button color="primary"
                          [raised]="true" buttonClass="hid-button primary" [loadingBool]="retreivingCertificate"
                          (clickEvent)="downloadPromRegStateRelatedCertificate()">Herunterladen
      </hid-loading-button>
      <span class="classic-link" (click)="copyUrlToClipboard()" >Link kopieren</span>
    </div>
  </div>

</div>
</div>


<div id="upsell" fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0">

    <h1>Das könnte dich auch interessieren:</h1>
    <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
      <app-offer-page-panel
        [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
        [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
      </app-offer-page-panel>
    </div>
  </div>

  <div *ngIf="recommendedUpsellPageIds.length == 0" fxLayout="column" fxLayoutAlign="center center" id="to-main-page">
    <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich</div>
    <img src="../../../../../assets/img/seminars2.jpg">
    <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
  </div>
</div>

