import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SemCheckoutS2LocationComponent} from './sem-checkout-s2-location/sem-checkout-s2-location.component';
import {SemCheckoutS2DatesComponent} from './sem-checkout-s2-dates/sem-checkout-s2-dates.component';
import {SemCheckoutS2UserComponent} from './sem-checkout-s2-user/sem-checkout-s2-user.component';
import {RouterModule, Routes} from '@angular/router';
import {SemCheckout2MainComponent} from './sem-checkout2-main/sem-checkout2-main.component';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {HidInteractionsModule} from '../../../hid-interactions/hid-interactions.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShareModule} from '../../../share/share.module';
import {GlobalModule} from '../../../global/global.module';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {ActionLinkModule} from '../../../actionLinks/action-link/action-link.module';
import {FeedbackStarsModule} from '../../../feedback-stars/feedback-stars.module';
import {AccountModule} from '../../../account/account.module';
import {DiscountsModule} from '../../../discounts/discounts.module';
import {MatButtonModule} from '@angular/material/button';
import {SeminarCheckoutS2EntryComponent} from './seminar-checkout-s2-entry/seminar-checkout-s2-entry.component';
import {GlobalCheckoutComponentsModule} from '../../components/global-checkout-components/global-checkout-components.module';


const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s2/entry', component: SeminarCheckoutS2EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s2', component: SemCheckout2MainComponent,
    children: [
      {path: 'location', component: SemCheckoutS2LocationComponent},
      {path: 'date', component: SemCheckoutS2DatesComponent},
      {path: 'user', component: SemCheckoutS2UserComponent},
    ]
  }];

@NgModule({
  declarations: [
    SemCheckoutS2LocationComponent,
    SemCheckoutS2DatesComponent,
    SemCheckoutS2UserComponent,
    SemCheckout2MainComponent,
    SeminarCheckoutS2EntryComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        BrowserModule,
        FlexModule,
        HidInteractionsModule,
        MatPaginatorModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        ShareModule,
        GlobalModule,
        NgxJsonLdModule,
        ActionLinkModule,
        FeedbackStarsModule,
        AccountModule,
        DiscountsModule,
        MatButtonModule,
      GlobalCheckoutComponentsModule]
})
export class SemCheckoutS2Module { }
