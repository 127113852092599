import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {
  BlogCategoryData,
  OgTagData,
  PagedData,
  PublicBlogPostData,
  PublicBlogPostPreviewData,
  PublicFormatPageData,
  PublicFormatPageOneClickSignUpData,
  PublicMetaFormatPageCheckoutOptionData,
  PublicOfferPagePreviewData
} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicOfferPageController
 */

@Injectable()
@ResourceParams({})
export class PublicOfferPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/blog/categories',
        endpoint: 'cms'
    })
    private _getBlogCategories: IResourceMethodObservableStrict<void, {}, {}, BlogCategoryData[]>;
    getBlogCategories(): Promise<BlogCategoryData[]> {
        return this._getBlogCategories(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts/{routingUrl}',
        endpoint: 'cms'
    })
    private _getBlogPage: IResourceMethodObservableStrict<void, {categoryId?: number}, {routingUrl: string}, PublicBlogPostData>;
    getBlogPage(routingUrl: string, requestParams?: {categoryId?: number}): Promise<PublicBlogPostData> {
        return this._getBlogPage(null, requestParams, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts/{id}/offers',
        endpoint: 'cms'
    })
    private _getBlogPageOffers: IResourceMethodObservableStrict<void, {categoryId?: number}, {id: number}, PublicOfferPagePreviewData[]>;
    getBlogPageOffers(id: number, requestParams?: {categoryId?: number}): Promise<PublicOfferPagePreviewData[]> {
        return this._getBlogPageOffers(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/blogposts',
        endpoint: 'cms'
    })
    private _getBlogPostsPreview: IResourceMethodObservableStrict<void, {q: string, categoryIds?: number[], page: number, pageSize: number}, {}, PagedData<PublicBlogPostPreviewData>>;
    getBlogPostsPreview(requestParams: {q: string, categoryIds?: number[], page: number, pageSize: number}): Promise<PagedData<PublicBlogPostPreviewData>> {
        return this._getBlogPostsPreview(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/{routingUrl}',
        endpoint: 'cms'
    })
    private _getFormatOfferPage: IResourceMethodObservableStrict<void, {forcedSplitId?: number, promId?: number}, {routingUrl: string}, PublicFormatPageData>;
    getFormatOfferPage(routingUrl: string, requestParams?: {forcedSplitId?: number, promId?: number}): Promise<PublicFormatPageData> {
        return this._getFormatOfferPage(null, requestParams, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/byCronosId/{cronosFormatId}',
        endpoint: 'cms'
    })
    private _getFormatOfferPageByCronosId: IResourceMethodObservableStrict<void, {forcedSplitId?: number}, {cronosFormatId: number}, PublicFormatPageData>;
    getFormatOfferPageByCronosId(cronosFormatId: number, requestParams?: {forcedSplitId?: number}): Promise<PublicFormatPageData> {
        return this._getFormatOfferPageByCronosId(null, requestParams, {cronosFormatId: cronosFormatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/metaPages/{routingUrl}/options',
        endpoint: 'cms'
    })
    private _getFormatOptionsForMetaPage: IResourceMethodObservableStrict<void, {}, {routingUrl: string}, PublicMetaFormatPageCheckoutOptionData[]>;
    getFormatOptionsForMetaPage(routingUrl: string): Promise<PublicMetaFormatPageCheckoutOptionData[]> {
        return this._getFormatOptionsForMetaPage(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pulic/offerpages/formatPages/descriptions/bySyncedFormatId',
        endpoint: 'cms'
    })
    private _getFormatPageDescriptionsBySyncedFormatId: IResourceMethodObservableStrict<void, {syncedFormatId: number}, {}, PublicFormatPageOneClickSignUpData>;
    getFormatPageDescriptionsBySyncedFormatId(requestParams: {syncedFormatId: number}): Promise<PublicFormatPageOneClickSignUpData> {
        return this._getFormatPageDescriptionsBySyncedFormatId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formatPages/bySyncedFormatId',
        endpoint: 'cms'
    })
    private _getFormatPageForSyncedFormat: IResourceMethodObservableStrict<void, {syncedFormatId: number}, {}, PublicOfferPagePreviewData>;
    getFormatPageForSyncedFormat(requestParams: {syncedFormatId: number}): Promise<PublicOfferPagePreviewData> {
        return this._getFormatPageForSyncedFormat(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formatPages/{cronosFormatId}/preview',
        endpoint: 'cms'
    })
    private _getFormatPagePreviewDataBySyncedFormatId: IResourceMethodObservableStrict<void, {}, {cronosFormatId: number}, PublicOfferPagePreviewData>;
    getFormatPagePreviewDataBySyncedFormatId(cronosFormatId: number): Promise<PublicOfferPagePreviewData> {
        return this._getFormatPagePreviewDataBySyncedFormatId(null, null, {cronosFormatId: cronosFormatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/formats/preview/{id}',
        endpoint: 'cms'
    })
    private _getFormatSplitPreview: IResourceMethodObservableStrict<void, {}, {id: string}, PublicFormatPageData>;
    getFormatSplitPreview(id: string): Promise<PublicFormatPageData> {
        return this._getFormatSplitPreview(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/banner/inline',
        endpoint: 'cms'
    })
    private _getInlinePageBanner: IResourceMethodObservableStrict<void, {splitId?: number}, {pid: number}, Blob>;
    getInlinePageBanner(pid: number, requestParams?: {splitId?: number}): Promise<Blob> {
        return this._getInlinePageBanner(null, requestParams, {pid: pid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{id}/preview',
        endpoint: 'cms'
    })
    private _getOfferPagePreviewData: IResourceMethodObservableStrict<void, {}, {id: number}, PublicOfferPagePreviewData>;
    getOfferPagePreviewData(id: number): Promise<PublicOfferPagePreviewData> {
        return this._getOfferPagePreviewData(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/upsell',
        endpoint: 'cms'
    })
    private _getOfferPageUpsellRecommendations: IResourceMethodObservableStrict<void, {}, {pid: number}, number[]>;
    getOfferPageUpsellRecommendations(pid: number): Promise<number[]> {
        return this._getOfferPageUpsellRecommendations(null, null, {pid: pid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/byCronosFormatId{fid}/upsell',
        endpoint: 'cms'
    })
    private _getOfferPageUpsellRecommendationsBySyncedFormatId: IResourceMethodObservableStrict<void, {}, {fid: number}, number[]>;
    getOfferPageUpsellRecommendationsBySyncedFormatId(fid: number): Promise<number[]> {
        return this._getOfferPageUpsellRecommendationsBySyncedFormatId(null, null, {fid: fid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/og',
        endpoint: 'cms'
    })
    private _getOgInfoForOfferPage: IResourceMethodObservableStrict<void, {}, {pid: number}, OgTagData>;
    getOgInfoForOfferPage(pid: number): Promise<OgTagData> {
        return this._getOgInfoForOfferPage(null, null, {pid: pid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/offerPages/{pid}/banner/side',
        endpoint: 'cms'
    })
    private _getSidePageBanner: IResourceMethodObservableStrict<void, {splitId?: number}, {pid: number}, Blob>;
    getSidePageBanner(pid: number, requestParams?: {splitId?: number}): Promise<Blob> {
        return this._getSidePageBanner(null, requestParams, {pid: pid}).toPromise()
    }

}
