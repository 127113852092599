import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SemCheckoutS3aZipCodeComponent} from './sem-checkout-s3a-zipCode/sem-checkout-s3a-zipCode.component';
import {SemCheckoutS3aDatesComponent} from './sem-checkout-s3a-dates/sem-checkout-s3a-dates.component';
import {SemCheckoutS3aUserComponent} from './sem-checkout-s3a-user/sem-checkout-s3a-user.component';
import {RouterModule, Routes} from '@angular/router';
import {SemCheckout3aMainComponent} from './sem-checkout3a-main/sem-checkout3a-main.component';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {HidInteractionsModule} from '../../../hid-interactions/hid-interactions.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShareModule} from '../../../share/share.module';
import {GlobalModule} from '../../../global/global.module';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {ActionLinkModule} from '../../../actionLinks/action-link/action-link.module';
import {FeedbackStarsModule} from '../../../feedback-stars/feedback-stars.module';
import {AccountModule} from '../../../account/account.module';
import {DiscountsModule} from '../../../discounts/discounts.module';
import {MatButtonModule} from '@angular/material/button';
import {SeminarCheckoutS3aEntryComponent} from './seminar-checkout-s3a-entry/seminar-checkout-s3a-entry.component';
import {
  GlobalCheckoutComponentsModule
} from '../../components/global-checkout-components/global-checkout-components.module';


const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s3a/entry', component: SeminarCheckoutS3aEntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s3a', component: SemCheckout3aMainComponent,
    children: [
      {path: 'location', component: SemCheckoutS3aZipCodeComponent},
      {path: 'date', component: SemCheckoutS3aDatesComponent},
      {path: 'user', component: SemCheckoutS3aUserComponent},
    ]
  }];

@NgModule({
  declarations: [
    SemCheckoutS3aZipCodeComponent,
    SemCheckoutS3aDatesComponent,
    SemCheckoutS3aUserComponent,
    SemCheckout3aMainComponent,
    SeminarCheckoutS3aEntryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    GlobalCheckoutComponentsModule,
  ]
})
export class SemCheckoutS3aModule { }
