import {Injectable} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class MediaService {


  mobileQuery: MediaQueryList;
  tabletQuery: MediaQueryList;

  constructor(media: MediaMatcher, private modal: NgbModal) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.tabletQuery = media.matchMedia('(max-width: 1180px)');
  }

  isTablet() {
    return this.tabletQuery.matches
  }

  isMobile(){
    return this.mobileQuery.matches
  }

  openModal(component, parameters: any, options: any){
    this.activateScrollLock()
    const modalRef = this.modal.open(component, options)
    for(let param in parameters){
      modalRef.componentInstance[param] = parameters[param];
    }
  }

  closeModals(){
    this.modal.dismissAll()
    this.deactivateScrollLock()
  }

  activateScrollLock(){
    document.getElementsByTagName('html')[0].setAttribute("style", "overflow-y: hidden !important;")
  }

  deactivateScrollLock(){
    document.getElementsByTagName('html')[0].removeAttribute("style")
  }
}
