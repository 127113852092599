import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {
  InternalMetaFormatCheckoutOptionCreationData,
  InternalMetaFormatCheckoutOptionUpdateData,
  InternalMetaFormatPageCheckoutOptionData,
  InternalMetaFormatPageData,
  InternalRedirectOfferPageData,
  JsonWrappedValue,
  MetaFormatPageUpdateData,
  RedirectPageUpdateData
} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalMetaFormatPageController
 */

@Injectable()
@ResourceParams({})
export class InternalMetaFormatPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/metaFormats',
        endpoint: 'cms'
    })
    private _createMetaFormatPage: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {}, InternalMetaFormatPageData>;
    createMetaFormatPage(requestBody: JsonWrappedValue<string>): Promise<InternalMetaFormatPageData> {
        return this._createMetaFormatPage(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/metaFormats/{id}/checkoutOptions',
        endpoint: 'cms'
    })
    private _createMetaFormatPageCheckoutOption: IResourceMethodObservableStrict<InternalMetaFormatCheckoutOptionCreationData, {}, {id: number}, void>;
    createMetaFormatPageCheckoutOption(requestBody: InternalMetaFormatCheckoutOptionCreationData, id: number): Promise<void> {
        return this._createMetaFormatPageCheckoutOption(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/redirect',
        endpoint: 'cms'
    })
    private _createRedirectPage: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {}, InternalRedirectOfferPageData>;
    createRedirectPage(requestBody: JsonWrappedValue<string>): Promise<InternalRedirectOfferPageData> {
        return this._createRedirectPage(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/offerPages/metaFormats/{id}',
        endpoint: 'cms'
    })
    private _deleteMetaFormatPage: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteMetaFormatPage(id: number): Promise<void> {
        return this._deleteMetaFormatPage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/offerPages/metaFormats/{id}/checkoutOptions',
        endpoint: 'cms'
    })
    private _deleteMetaFormatPageCheckoutOption: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteMetaFormatPageCheckoutOption(id: number): Promise<void> {
        return this._deleteMetaFormatPageCheckoutOption(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/metaFormats/{id}',
        endpoint: 'cms'
    })
    private _getMetaFormatPage: IResourceMethodObservableStrict<void, {}, {id: number}, InternalMetaFormatPageData>;
    getMetaFormatPage(id: number): Promise<InternalMetaFormatPageData> {
        return this._getMetaFormatPage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/metaFormats/{id}/checkoutOptions',
        endpoint: 'cms'
    })
    private _getMetaFormatPageCheckoutOptions: IResourceMethodObservableStrict<void, {}, {id: number}, InternalMetaFormatPageCheckoutOptionData[]>;
    getMetaFormatPageCheckoutOptions(id: number): Promise<InternalMetaFormatPageCheckoutOptionData[]> {
        return this._getMetaFormatPageCheckoutOptions(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/metaFormats',
        endpoint: 'cms'
    })
    private _getMetaFormatPages: IResourceMethodObservableStrict<void, {}, {}, InternalMetaFormatPageData[]>;
    getMetaFormatPages(): Promise<InternalMetaFormatPageData[]> {
        return this._getMetaFormatPages(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/metaFormats/{id}/active',
        endpoint: 'cms'
    })
    private _updateFormatPageActive: IResourceMethodObservableStrict<void, {active: boolean}, {id: number}, JsonWrappedValue<boolean>>;
    updateFormatPageActive(id: number, requestParams: {active: boolean}): Promise<boolean> {
        return this._updateFormatPageActive(null, requestParams, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/metaFormats/{id}',
        endpoint: 'cms'
    })
    private _updateMetaFormatPage: IResourceMethodObservableStrict<MetaFormatPageUpdateData, {}, {id: number}, void>;
    updateMetaFormatPage(requestBody: MetaFormatPageUpdateData, id: number): Promise<void> {
        return this._updateMetaFormatPage(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/metaFormats/{id}/checkoutOptions',
        endpoint: 'cms'
    })
    private _updateMetaFormatPageCheckoutOption: IResourceMethodObservableStrict<InternalMetaFormatCheckoutOptionUpdateData, {}, {id: number}, void>;
    updateMetaFormatPageCheckoutOption(requestBody: InternalMetaFormatCheckoutOptionUpdateData, id: number): Promise<void> {
        return this._updateMetaFormatPageCheckoutOption(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/redirect/{id}',
        endpoint: 'cms'
    })
    private _updateRedirectPage: IResourceMethodObservableStrict<RedirectPageUpdateData, {}, {id: number}, void>;
    updateRedirectPage(requestBody: RedirectPageUpdateData, id: number): Promise<void> {
        return this._updateRedirectPage(requestBody, null, {id: id}).toPromise()
    }

}
