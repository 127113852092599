import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PartialCustomDimensionsSet} from './partial-custom-dimensions-set';
import {DataLayerCustomDimensionsService} from './data-layer-custom-dimensions.service';
import {LocalStorageService} from '../local-storage.service';
import {environment} from '../../environments/environment';
import {DataLayerCustomDimensions} from './data-layer-custom-dimensions.enum';
import {GDPRConsentService} from '../gdprconsent/gdprconsent.service';
import {InternalCookieService} from '../internal-cookie.service';
import {LeadQualifier, PublicFormatPageData} from '../generated/cms/data';
import {DataLayerService} from '@piwikpro/ngx-piwik-pro';

function gtag(){window['dataLayer'].push(arguments);}

export type CheckoutTrackingEvent = {
    event: String;
    eventName: string; //clone of "event" for some tracking tools
    formatId: number;
    formatType: string,
    pageSpid: number,
  leadQualifier?: LeadQualifier
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private gdprConsentService: GDPRConsentService,
    private dataLayerCustomDimensionsService: DataLayerCustomDimensionsService,
    private internalCookieService: InternalCookieService,
    private piwikDataLayerService: DataLayerService
  ) {
  }


  reportTripleOptInStatus(tripleOptIn: boolean, tripleOptInCode: string) {
    let preparedEvent = {event: 'TripleOptIn',tripleOptIn: tripleOptIn, tripleCode: tripleOptInCode};
    this.pushEvent( preparedEvent);
  }


  reportOfferpageView(page:PublicFormatPageData){
    let event = {
      event: "OfferPageView",
      formatId:  page.cronosFormatId,
      formatType: page.formatType,
      pageSpid: page.selectedSplitId,
      leadQualifier: page.leadQualifier
    }
    this.pushEvent(event)
  }

  reportCheckoutTrackingEvent(eventName: string, page:PublicFormatPageData, additionalParams: { [key: string]: string } = null){
    let event: CheckoutTrackingEvent = {
      event: eventName,
      eventName: eventName,
      formatId:  page.cronosFormatId,
      formatType: page.formatType,
      pageSpid: page.selectedSplitId,
      leadQualifier: page.leadQualifier
    }
    for(let key in additionalParams){
      event[key] = additionalParams[key];
    }
    this.pushEvent(event)
  }


  reportRegistration(accountOrigin: string) {
    let preparedEvent = {
      event: 'AccountRegistration',accountOrigin: accountOrigin};
    this.pushEvent(preparedEvent);
  }


  reportPageView() {
    const url = this.router.url;
    const sessionInfo = this.localStorageService.getLastSessionInfoUpdate();

    if (this.consideredAsPageView(url)) {
      this.pageView({
        [DataLayerCustomDimensions.PageTitle]: document.title,
        [DataLayerCustomDimensions.UrlParams]: url.indexOf('?') != -1 ? url.split('?')[1] : undefined,
        [DataLayerCustomDimensions.UtmSource]: sessionInfo ? sessionInfo.utm_source : undefined,
        [DataLayerCustomDimensions.UtmContent]: sessionInfo ? sessionInfo.utm_content : undefined,
        [DataLayerCustomDimensions.UtmMedium]: sessionInfo ? sessionInfo.utm_medium : undefined,
        [DataLayerCustomDimensions.UtmCampaign]: sessionInfo ? sessionInfo.utm_campaign : undefined,
      });
    }
  }


  /**
   * Evaluate whether a virtual page view should be fired or not.
   * This function needs to be modified, if more than one route
   * won't be considered as a page view
   * @param path the routing path
   */
  private consideredAsPageView(path: string):boolean {
    //exclude certain checkout pathes
    if(path.indexOf("checkout") > -1){
      let split = path.split("/")
      let last = split[split.length -1].split("?")[0]

      // /g is reserved for gateways, /entry is reserved for entry comps
      if(["g", "entry"].indexOf(last) >-1) return false
    }
    return true

  }

  /**
   * Method that will generate a virtual pageview
   * @param dimensions custom dimensions to push into data layer
   * @param overridePath will override the page view url sent to GTM
   */
  private pageView(dimensions: PartialCustomDimensionsSet, overridePath?: string) {
    this.dataLayerCustomDimensionsService.dimensions = dimensions;
    this.dataLayerCustomDimensionsService.trigger();
    let url: string = this.router.url;
    if (url.includes('#3')) {
      url = url.substr(0, url.indexOf('#3'))
    }

    const pageView = {
      event: 'VirtualPageview',
      virtualPagePath: overridePath || url,
      consent_facebook: this.gdprConsentService.consent.consent_facebook,
      consent_ga: this.gdprConsentService.consent.consent_ga,
      consent_linkedIn: this.gdprConsentService.consent.consent_linkedIn,
      consent_pinterest: this.gdprConsentService.consent.consent_pinterest,
      consent_google_ads: this.gdprConsentService.consent.consent_google_ads,
      consent_tiktok: this.gdprConsentService.consent.consent_tiktok,
      consent_hotjar: this.gdprConsentService.consent.consent_hotjar,
      consent_bing: this.gdprConsentService.consent.consent_bing
    };

    //Not using pushevent here, because VPVs don't need to go to piwik
    (<any>window).dataLayer.push(pageView);

  }

  /**Pushes event to datalayer for GTM and Matomo**/
  private pushEvent(event: object){
    // (<any>window).dataLayer.push(event);
    this.piwikDataLayerService.push(event)
  }


  pushGoogleConsents(adsConsent: boolean, analyticsConsent: boolean, consentMode: string) {
    let adStorage = 'denied'
    if (adsConsent) adStorage = 'granted'
    let analyticsStorage = 'denied'
    if (analyticsConsent) analyticsStorage = 'granted'


    // @ts-ignore
    gtag('consent', consentMode, {
      ad_storage: adStorage,
      analytics_storage: analyticsStorage
    });
  }


  initGtm() {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      originalLocation: document.location.protocol + '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search
    });

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j: any = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', environment.gtmId);

    if(this.internalCookieService.getGDPRConsent() != null) {
      let consent = this.internalCookieService.getGDPRConsent()
      this.pushGoogleConsents(consent.consent_google_ads, consent.consent_ga, 'update')
    } else {
      this.pushGoogleConsents(false, false, 'default')
    }
  }


}
