import { Component, OnInit } from '@angular/core';
import {AccountResource} from "../../generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  confirming: boolean = false;
  confirmationToken: string;
  init: boolean = false;
  emailConfirmed: boolean;

  constructor(private accountResource: AccountResource,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService) {
    this.route.queryParamMap.subscribe(params => {
      this.confirmationToken = params.get("confirmationToken")
    })
  }

  ngOnInit(): void {
  }

  confirmEmail() {
    this.init = true;
    this.confirming = true;
    this.accountResource.confirmEmail({emailConfirmationToken: this.confirmationToken}).then(
      result => {
        this.emailConfirmed = result.emailConfirmed;
        this.authService.setToken(result.jwtToken);
        this.confirming = false;
      }
    )
  }

  confirmEmailFake(){
    this.confirming = true
    this.emailConfirmed = true
  }
}
