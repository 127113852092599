
<div class="content-wrapper">

  <div *ngIf="initialized">
  <h1 class="mt-3 text-center">Erfahrungen mit der Hochschulinitiative Deutschland</h1>
    <div fxLayout="row" fxLayoutGap="10px">
  <label class="text-regular text-grey">
    Nach der Teilnahme an unseren Workshops bekommen Studierende die Möglichkeit, die Seminare auf einer Skala von 1 bis 5 Sternen zu bewerten sowie einen optionalen Kommentar zu hinterlassen.
    Die gesammelten Erfahrungen über die Hochschulinitiative Deutschland findest du hier.
    Da alle getätigten Kommentare auf rechtswidrige Inhalte geprüft werden, verzögert sich die Veröffentlichung ggf. um einige Tage.
   </label>
    </div>

  <div class="text-grey" style="margin-top:20px;margin-bottom: 10px"> Filtern nach Workshop-Thema</div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="filter" fxLayoutGap="10px" *ngIf="initialized">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
         class="bubble"
         [ngStyle]="{'background-color': this.selectedTopic.identifier == 'global' ? 'lightgrey' : 'white' }"
         (click)="selectTopic('global')" >
      <div fxHide.lt-sm class="img allImg" role="img" alt="Logo Hochschulinitiative Deutschland"></div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>Alle - <i class="fa fa-star star"></i> {{getGroup('global').getAverageRating()}}</span>
        <div class="text-grey feedbackcount">{{getGroup('global').getTotalCount()}} Erfahrungen</div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"  class="bubble"
         [ngStyle]="{'background-color': this.selectedTopic.identifier == 'steuer' ? 'lightgrey' : 'white' }"
         (click)="selectTopic('steuer')" >
      <div fxHide.lt-sm class="img steuerImg" role="img" alt="Steuerseminar Hochschulinitiative Deutschland"></div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>Steuer - <i class="fa fa-star star"></i> {{getGroup('steuer').getAverageRating()}}</span>
        <div class="text-grey feedbackcount">{{getGroup('steuer').getTotalCount()}} Erfahrungen</div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"  class="bubble"
         [ngStyle]="{'background-color': this.selectedTopic.identifier == 'thesis' ? 'lightgrey' : 'white' }"
         (click)="selectTopic('thesis')" >
      <div fxHide.lt-sm class="img thesisImg" role="img" alt="Thesis-Seminar Hochschulinitiative Deutschland" ></div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>Thesis - <i class="fa fa-star star"></i> {{getGroup('thesis').getAverageRating()}}</span>
        <div class="text-grey feedbackcount">{{getGroup('thesis').getTotalCount()}} Erfahrungen</div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"  class="bubble"
         [ngStyle]="{'background-color': this.selectedTopic.identifier == 'excel' ? 'lightgrey' : 'white' }"
         (click)="selectTopic('excel')" >
      <div fxHide.lt-sm class="img excelImg" role="img" alt="Excel-Kurs Hochschulinitiative Deutschland"></div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>Excel - <i class="fa fa-star star"></i> {{getGroup('excel').getAverageRating()}}</span>
        <div class="text-grey feedbackcount">{{getGroup('excel').getTotalCount()}} Erfahrungen</div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"  class="bubble"
         [ngStyle]="{'background-color': this.selectedTopic.identifier == 'finance' ? 'lightgrey' : 'white' }"
         (click)="selectTopic('finance')" >
      <div fxHide.lt-sm class="img financeImg" role="img" alt="Finanz-Kurs Hochschulinitiative Deutschland"></div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>Finanzen - <i class="fa fa-star star"></i> {{getGroup('finance').getAverageRating()}}</span>
        <div class="text-grey feedbackcount">{{getGroup('finance').getTotalCount()}} Erfahrungen</div>
      </div>
    </div>
  </div>


  <div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start"  class="mt-3 percent-wrapper">
      <div *ngFor="let i of [5,4,3,2,1]" class="percent" fxLayout="row" fxLayoutAlign="start start" [ngStyle]="{'background-color': selectedRating == i ? 'lightgrey' : 'white' }" (click)="loadFeedbackDataPage(page, i)">
        <div class="pl-1" fxLayout="row" fxLayoutGap="3px">
          <span>{{i}} Sterne</span>
          <div class="percent-bar" [ngStyle]="{'background-color' : selectedRating == i ? 'white' : 'lightgrey'}">
            <div class="percent" [ngStyle]="{'width.px': (this.selectedTopic.getPercentageForCount(i) * 80 / 100)}"></div>
          </div>
          <span>{{this.selectedTopic.getPercentageForCount(i)}} %</span>
          <i class="fas fa-times cancel" *ngIf="selectedRating == i"></i>
        </div>
      </div>
    </div>

    <hid-loading-spinner [loadingBool]="feedbackLoading">

  <div *ngFor="let singleFeedback of feedback" fxLayout="column" fxLayoutGap="10px" class="mt-3">
    <div fxLayout="column" class="mt-3">
      <div fxLayout="row"><app-feedback-stars [rating]="singleFeedback.rating"></app-feedback-stars>
        <span class="pl-2" *ngIf="singleFeedback.lastNameFirstLetter == null">{{singleFeedback.firstName}}</span>
        <span class="pl-2" *ngIf="singleFeedback.lastNameFirstLetter != null">{{singleFeedback.firstName}} {{singleFeedback.lastNameFirstLetter}}.</span></div>
    <div class="text-grey isu-text-fontsize-14">{{singleFeedback.formatTitle}} in {{singleFeedback.locationName}} am {{singleFeedback.seminarDate | date: 'dd.MM.yy'}}</div>
    <div class="comment">
      <span *ngIf="singleFeedback.comment" >&bdquo;{{singleFeedback.comment}}&ldquo;</span>
    <span *ngIf="!singleFeedback.comment" class="no-comment-available">Es wurde kein Kommentar hinterlassen</span>
    </div>
      <div *ngIf="singleFeedback.feedbackResponse != null" class="response-box" fxLayout="column" fxLayoutAlign="start start">
        <div class="responseFooter"> Unser Team hat diese Bewertung am {{singleFeedback.feedbackResponseDate | date: 'dd.MM.yy'}} beantwortet:</div>
        <p>{{singleFeedback.feedbackResponse}}</p>
      </div>
  </div>
</div>
    </hid-loading-spinner>
  </div>

  <div class="button-bar"></div>
  <hid-loading-button color="primary" class="text-center m-auto pr-3"
                      [raised]="true" buttonClass="hid-button primary" (clickEvent)="loadFeedbackDataPage(page+1, null)" [disabledCondition]="feedbackLoading || totalElements == feedback.length">Mehr Feedbacks laden</hid-loading-button>

  </div>
</div>
