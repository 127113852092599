import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {SeminarCheckoutService} from "../../../seminar-checkout/seminar-checkout.service";

@Component({
  selector: 'app-sta-checkout-s1-main',
  templateUrl: './sta-checkout-s1-main.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sta-checkout-s1-main.component.scss']
})
export class StaCheckoutS1MainComponent implements OnInit, OnDestroy {


  constructor(private globalCheckoutService: GlobalCheckoutService) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    //Forewarding will get disabled once a step is reached, that requires input. However, if you just leave the checkout, the forewarding should be enabled again for the next time
    this.globalCheckoutService.forewardingEnabled = true
  }

}
