import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PublicLocationData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.PublicStructureControllerV2
 */

@Injectable()
@ResourceParams({})
export class PublicStructureResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/zipCode',
        endpoint: 'cronos'
    })
    private _doesZipCodeExist: IResourceMethodObservableStrict<void, {zipCode: string}, {}, JsonWrappedValue<boolean>>;
    doesZipCodeExist(requestParams: {zipCode: string}): Promise<JsonWrappedValue<boolean>> {
        return this._doesZipCodeExist(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/cities',
        endpoint: 'cronos'
    })
    private _getCities: IResourceMethodObservableStrict<void, {}, {}, any>;
    getCities(): Promise<any> {
        return this._getCities(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/location/byId',
        endpoint: 'cronos'
    })
    private _getLocationById: IResourceMethodObservableStrict<JsonWrappedValue<number>, {}, {}, PublicLocationData>;
    getLocationById(requestBody: JsonWrappedValue<number>): Promise<PublicLocationData> {
        return this._getLocationById(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/locations',
        endpoint: 'cronos'
    })
    private _getLocations: IResourceMethodObservableStrict<void, {}, {}, any>;
    getLocations(): Promise<any> {
        return this._getLocations(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/location/cityId',
        endpoint: 'cronos'
    })
    private _getNearestLocationFromCityId: IResourceMethodObservableStrict<void, {cityId: number}, {}, PublicLocationData>;
    getNearestLocationFromCityId(requestParams: {cityId: number}): Promise<PublicLocationData> {
        return this._getNearestLocationFromCityId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/location/zipCode',
        endpoint: 'cronos'
    })
    private _getNearestLocationFromZipCode: IResourceMethodObservableStrict<void, {zipCode: string}, {}, PublicLocationData>;
    getNearestLocationFromZipCode(requestParams: {zipCode: string}): Promise<PublicLocationData> {
        return this._getNearestLocationFromZipCode(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/studyAreas',
        endpoint: 'cronos'
    })
    private _getStudyAreas: IResourceMethodObservableStrict<void, {}, {}, any>;
    getStudyAreas(): Promise<any> {
        return this._getStudyAreas(null, null, null).toPromise()
    }

}
