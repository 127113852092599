<div class="margin-bottom-1-5" fxLayout="column" fxLayoutAlign="center center">
  <h2 [routerLink]="'/kategorien/'+bundle.routingUrl" class="pointer bundle-caroussel-heading" [innerHTML]="bundle.title | safeHtml">
    <span
    fxShow.xs
    fxHide>></span>
  </h2>
  <div class="bundle-caroussel" fxLayout="row" fxLayoutAlign="start start">
    <div class="pointer arrow arrow-left" (click)="leftScroll()" *ngIf="showLeftArrow" fxLayout="column"
         fxLayoutAlign="center center">
      <i class="fa fa-chevron-left"></i>
    </div>


    <div class="desktop-bundle-caroussel-scroller" #caroussel fxLayout="row" fxHide.xs appOnScroll
         (onScroll)="checkArrows()"
         fxLayoutAlign="start start">
      <div fxLayout="row" #carousselContent class="scroll-content" *ngIf="!isMobile"
           [ngStyle]="{'transform': 'translateX(' + currentScrollOffset + 'px);'}">
        <app-offer-page-panel [externallyProvidedPage]="page" *ngFor="let page of pages" [trackedClick]="false"
        ></app-offer-page-panel>
        <div fxLayout="row" >
          <app-offer-last-page-panel>

          </app-offer-last-page-panel>
<!--          <img src="assets/img/carousell_instagram.png" alt="instagram"/>-->
        </div>
      </div>
    </div>


    <div id="mobile-bundle-caroussel-scroller" fxHide fxShow.xs *ngIf="isMobile">
      <ngx-slick-carousel class="carousel" [config]="slickConfig">

        <div ngxSlickItem *ngFor="let page of pages">
          <app-offer-page-panel [externallyProvidedPage]="page" ngxSlickItem [trackedClick]="false"
          ></app-offer-page-panel>
        </div>
        <app-offer-last-page-panel>

        </app-offer-last-page-panel>
      </ngx-slick-carousel>
    </div>


    <div class="pointer arrow arrow-right" (click)="rightScroll()" *ngIf="showRightArrow" fxLayout="column"
         fxLayoutAlign="center center">
      <i class="fa fa-chevron-right"></i>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
    <a class="bundle-link" fxShow fxHide.xs
       [routerLink]="'/kategorien/'+bundle.routingUrl">mehr aus dieser Kategorie ></a>
  </div>

</div>
