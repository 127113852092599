import {Injectable} from '@angular/core';
import {GlobalCheckoutService} from '../../global-checkout.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeminarCheckoutS2ProcessService {

  splitIdentifier: string = 'Seminar-2';

  constructor(public globalCheckoutService: GlobalCheckoutService) {
  }

  reportTrackingEvent(eventName: string, additionalParams: { [key: string]: string } = null) {
    let params = additionalParams;
    if (params) {
      additionalParams.hidSplit = this.splitIdentifier;
      this.globalCheckoutService.reportTrackingEvent(eventName, params);
    } else {
      this.globalCheckoutService.reportTrackingEvent(eventName, {hidSplit: this.splitIdentifier});
    }

  }

  prepareSplitRoutingUrl(route: ActivatedRoute, customUrl: string): string {
    let offerUrl = (route.params as BehaviorSubject<any>).value['routingUrl'];
    return 'angebote/' + offerUrl + '/' + customUrl;
  }
}
