<!--[ngClass]="{'tp-test': page.cronosFormatId == 2166}"-->
<!--
  [ngClass]="{'fullscreen': page.fullScreen}" is not working because of our routing setup. Class is added manually in loadPage()
!-->
<div #offerPageWrapper
     id="ita_offer-page-wrapper"
     class="content-wrapper ita_format-split-{{page.selectedSplitId}}" fxLayout="row"
     fxLayoutAlign="center start">
  <!-- custom style is current workaround for TP, remove if test is finished -->
  <hid-loading-spinner
                       [ngClass]="{'page-content': showSidebar, 'full-page-content': !showSidebar}"
                       [loadingSubject]="loading"
                       [transparency]="false"
                       [enableWaitingTimeEntertainment]="true">
    <ngx-json-ld [json]="ratingLD" *ngIf="page.averageFeedbackRating"></ngx-json-ld>
    <!--  <div fxLayout="row" *ngIf="page.averageFeedbackRating" fxLayoutAlign="start start">
          <app-feedback-stars [rating]="rating"></app-feedback-stars>

        <span class="hint" fxLayout="row" fxLayoutAlign="center end"> {{page.feedbackCount}} Bewertungen | {{page.registrationCount}} Anmeldungen</span>
      </div>-->

    <!-- This was just a test for some routing bug -->
    <!--button style="height: 400px;" (click)="scrollToTarget('quiz1'); $event.stopPropagation()">QUIZ</button-->


    <div [innerHTML]="page.content | safeHtml"></div>

    <!-- This was just a test for some routing bug -->
    <!--div id="quiz1" style="height: 400px; width: 400px; background: black; position: relative"></div-->

    <!--div *ngIf="routingUrl == 'dealwunder'">

      <button *ngIf="showMobileStickyButton && mediaService.isMobile()" (click)="scrollToRegister('RegisterComp')"
              mat-flat-button class="dealwunder-sticky-button">Registrieren</button>

      <app-weekly-update-timer [offerPage]="true"></app-weekly-update-timer>
      <app-register id="RegisterComp" [calledFromLp]="true"></app-register>

      <div class="w-100" fxLayoutAlign="center center" fxLayout="column" style="padding: 20px">
        <p style="color: black; font-size: 14px; text-align: center; margin: 0px 0px 10px 0px;">Schon registriert? Melde dich an und sichere dir deine Rabatte!</p>
        <button mat-button class="signup-button-secondary" routerLink="/account/login">Anmelden</button>
      </div>

      <div style="max-width: 600px; padding: 20px; margin: 0px auto;">
        <p style="color: black; font-size: 22px; text-align: center; font-weight: bold; margin-top: 33px; margin-bottom: 18px;">Neu für dich: DealWunder 🔥</p>
        <p style="color: black; font-size: 16px; text-align: center;">
          Bei DealWunder findest als Student Rabatte, die dir Shoppen auch mit kleinem Geldbeutel möglich machen. Bei uns findest du immer den passenden Studenten Rabatt für deine Lieblingsmarken. Jede Woche bekommst du neue Rabatte und Aktionen von unseren ausgewählten Partnerfirmen. Wir halten dich immer auf dem Laufenden, damit du keinen Studenten Discount verpasst!
        </p>
      </div>
    </div-->
    <div fxLayout="row" fxHide fxShow.xs fxLayoutAlign="center center" style="margin-top: 20px; width: 100%; height: 100%; min-height: 50px"
         *ngIf="!loading.getValue()">
      <button class="hid-button secondary inverse" (click)="initShare()">Angebot teilen <i class="fa fa-share-alt"></i>
      </button>
    </div>

    <!-- Marketing wanted ratings out for now
    <div #ratings id="ratings" style="position: relative">

      <hr>

      <app-feedback-display *ngIf="page.averageFeedbackRating && page.feedbackCount > 0" [formatId]="page.cronosFormatId"
                            ></app-feedback-display>
    </div>
    -->

  </hid-loading-spinner>

  <div *ngIf="showSidebar" id="side-bar" fxLayout="column" fxLayoutAlign="start center">
    <div class="image-wrapper">
      <img id="offer-image" [src]="imageSrc" #offerImage>
      <div id="fake-image" class="fake" *ngIf="loading.getValue()"></div>
    </div>
    <button *ngIf="page.cmsOfferPageType !== 'RedirectOfferPage'"
            [className]="'cta-button ita_initiate-checkout ' + trackingClasses" (click)="initiateCheckout($event)"
            [disabled]="loading.getValue()">
      {{page.ctaText}}
    </button>
    <a *ngIf="page.cmsOfferPageType === 'RedirectOfferPage'" (click)="initiateCheckout($event)"
       [className]="'cta-button ita_initiate-checkout ' + trackingClasses"
       target="_blank"
       [href]="completeRedirectUrl">      {{page.ctaText}}</a>



    <div fxLayout="row">
      <button class="hid-button secondary inverse" (click)="initShare()">Angebot teilen <i class="fa fa-share-alt"></i></button>
    </div>


    <div fxLayout="column" id="side-text" [innerHTML]="page.sideText | safeHtml">
    </div>


<!--    <div *ngIf="routingUrl == 'dealwunder'">-->
<!--      <app-weekly-update-timer [offerPage]="true"></app-weekly-update-timer>-->
<!--      <app-register [calledFromLp]="true"></app-register>-->


<!--      <div style="max-width: 600px; padding: 20px; margin: 0px auto;">-->
<!--        <p style="color: black; font-size: 22px; text-align: center; font-weight: bold; margin-top: 33px; margin-bottom: 18px;">Neu für dich: Die Uniwunder Welt 🔥</p>-->
<!--        <p style="color: black; font-size: 16px; text-align: center;"> Uniwunder ist die ultimative Studentenplattform. Bei uns kannst du dich mit anderen Studenten vernetzen, exklusive Rabatte erhalten und dein Studium auf das nächste Level bringen. Zusätzlich bieten wir dir hochwertige Lernunterlagen, damit du dich optimal auf dein Studium vorbereiten kannst. Mit Uniwunder bist du stets top informiert und bestens für deine Prüfungen gewappnet!</p>-->
<!--      </div>-->
<!--    </div>-->

    <div fxLayout="column" fxLayoutAlign="start center" id="questions">
      <h4>Fragen? Schreib Lisa</h4>
      <img src="../../../assets/img/hp_lisa.jpg" id="lisa">
      <div>
        <i aria-hidden="true" class="far fa-comments fam"></i>
        <span class="hid-link" (click)="openContactDialog()">Kontakt aufnehmen</span>
      </div>
    </div>
  </div>

  <div id="mobile-cta"
       [ngClass]="{'hide': vanishMobileCta}"
       fxLayout="column" fxLayoutAlign="center center" *ngIf='showMobileCta'>
    <button
      id="mobile-cta-button"
      [className]="'cta-button ita_initiate-checkout ' + trackingClasses"
      *ngIf="page.cmsOfferPageType !== 'RedirectOfferPage'"
      [disabled]="loading.getValue()"
      (click)="initiateCheckout($event)">
      <span *ngIf="!loading.getValue()">{{page.ctaText}}</span>
      <hid-progress-spinner *ngIf="loading.getValue()"></hid-progress-spinner>
    </button>
    <a *ngIf="page.cmsOfferPageType === 'RedirectOfferPage'"
       [className]="'cta-button ita_initiate-checkout ' + trackingClasses"
       (click)="initiateCheckout($event)"
       [href]="page.redirectUrl + trackingService.generateUrlTailFromTracking(this.localStorageService.getLastSessionInfoUpdate(), page.redirectUrl)">
      <span *ngIf="!loading.getValue()">{{page.ctaText}}</span>
    </a>

    <div *ngIf="!isTalentAgentOffer" class="mobile-contact-data">
      <span>
        <span class="margin-right5">Fragen? Schreib Lisa!</span>
        <i aria-hidden="true" class="fab fa-whatsapp fam"></i>
        <a class="link-classic" href="whatsapp://send?text=Hey Lisa, ich hab eine Frage:&amp;phone=+4915735980244">+49 157 359 80 244</a>
      </span>
    </div>

    <div *ngIf="isTalentAgentOffer" class="mobile-contact-data">
      <span>
        <span class="margin-right5">Du hast bereits ein Konto?</span>
        <a class="link-classic" href="https://app.talentagent.de/">Einloggen</a>
      </span>
    </div>
  </div>
</div>
