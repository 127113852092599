<div id="header">
  <div class="overlay">
    <div id="header-content" fxLayout="column" fxLayoutAlign="center center">
      <div *ngIf="!loading">
        <div fxLayout="column" fxLayoutAlign="center center" class="isu-padding" *ngIf="iteminfo.registrationData">
          <p style="filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.7));">
            Dein kostenfreies {{iteminfo.registrationData.promotion.type | promotionType}}</p>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="!this.loading && this.iteminfo.registrationData != null"
     class="content-wrapper ita_promotion-type-{{iteminfo.registrationData.promotion.formatType}} ita_format-{{iteminfo.registrationData.promotion.formatId}}"
     id="ita_checkout-modal">
  <!-- This can only deal with seminars so far since other stuff is not supported yet anyway (cronos side)-->
  <hid-loading-spinner [loadingBool]="loading || submitting || redirecting">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!loading">
      <div class="itemInfo  mat-elevation-z3 white-background height-100" fxLayout="column"
           fxLayoutAlign="center space-between"
           *ngIf="iteminfo.state === 'VALID' && (iteminfo.registrationData.promotion.type == 'OfflineSeminarDate'
           || iteminfo.registrationData.promotion.type == 'SingleWebinarDate') ">
        <div fxLayout="column" fxLayoutAlign="center center">
          <h4>Dein Termin:</h4>
          <h2>{{iteminfo.registrationData.promotion.title}}  </h2>
          <hid-loading-spinner [loadingBool]="loadingDescription">
            <div fxLayout="column" fxLayoutAlign="center center">
              <span style="padding-bottom: 10px">
              <app-feedback-stars *ngIf="!loadingDescription"
                                  [rating]="formatDescriptionData.averageRating"></app-feedback-stars>
            </span>
              <div class="description" [innerHtml]="formatDescriptionData.shortDescription"></div>
            </div>

          </hid-loading-spinner>
          am
          <div *ngIf="selectedSeminar">
            <b>{{selectedSeminar.date | date:'EEEE dd.MM.yyyy HH:mm'}}</b><span
            *ngIf="selectedSeminar.type == 'OfflineSeminarDate'">  <br>
          <i class="fas fam fa-map-marker-alt"></i>{{selectedSeminar.address}}
          </span>
          </div>

          <br>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="iteminfo.registrationData.status === 'Success'" fxLayout="column"
               fxLayoutAlign="center center">
            <hid-checkbox (modelChange)="privacyConsentGiven = $event.valueOf()" inputClass="ita_check_privacy"
                          style="font-size: 14px; margin-top:20px;">
              Ich willige in die Verarbeitung gemäß der Erklärung auf der
              <a [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">
                Datenschutzseite
              </a>
              ein.
            </hid-checkbox>
            <span *ngIf="showPrivacyConsentError" class="hid-validationError" style="margin-left: 65px;">
            Bitte bestätige uns die Datenverarbeitung<br>
          </span>
            <button (click)="signUp()" class="hid-button primary ita_finalize-checkout  ita_finalize-checkout-oneclick">
              {{formatDescriptionData.ctaText}}
            </button>
            <div class="cta-description" [innerHTML]="formatDescriptionData.ctaDescription | safeHtml"></div>
          </div>
          <!-- Display warning/error and show alternate dates -->
          <p *ngIf="iteminfo.registrationData.status == 'Full' || iteminfo.registrationData.status == 'Unavailable'"
             class="text-red">Das Angebot ist leider nicht mehr
            verfügbar.</p>
          <br>
          <p
            *ngIf="iteminfo.registrationData.status == 'Success' && alternativeSeminars && alternativeSeminars.length > 1">
            Schon verhindert? Hier sind {{alternativeSeminars.length}} Termine:</p>
          <p
            *ngIf="iteminfo.registrationData.status == 'Success' && alternativeSeminars && alternativeSeminars.length == 1">
            Schon verhindert? Hier ist ein Termin:</p>
          <p *ngIf="iteminfo.registrationData.status != 'Success'">Keine Sorge! Hier sind die nächsten Termine:</p>

          <div *ngIf="loadingAlternativeSeminars || alternativeSeminars.length > 0"
               style="width: 80%; max-width: 40rem">
            <hid-loading-spinner style="position: relative" [loadingBool]="loadingAlternativeSeminars">

              <app-checkout-seminardate-segmentation *ngIf="alternativeSeminars.length > 0 && selectedSeminar"
                                                     [seminarDates]="alternativeSeminars"
                                                     [selectedDateSubject]="promotionSubject"
                                                     [displayedSeminarAmountSubject]="totalDisplayedSeminarsAmountSubject"
                                                     [initialSelectedSeminar]="selectedSeminar"></app-checkout-seminardate-segmentation>
            </hid-loading-spinner>

          </div>

          <button class="hid-button secondary inverse" *ngIf="iteminfo.registrationData.promotion != null"
                  style="margin: 2rem"
                  (click)="redirectToOfferPage()">Anderen Termin finden
          </button>

          <button class="hid-button secondary inverse" *ngIf="iteminfo.registrationData.promotion == null"
                  routerLink="/home">Anderes Angebot finden
          </button>
        </div>
      </div>
      <div class="itemInfo  mat-elevation-z3 white-background height-100" fxLayout="column"
           fxLayoutAlign="center space-between"
           *ngIf="iteminfo.state === 'VALID' && iteminfo.registrationData.promotion.type == 'UniqueEvent'">
        <div fxLayout="column" fxLayoutAlign="center center">
          <h2>Dein Termin:</h2>
          <h4>{{iteminfo.registrationData.promotion.title}}  </h4>
          <hid-loading-spinner [loadingBool]="loadingDescription">
            <div fxLayout="column" fxLayoutAlign="center center">
              <span style="padding-bottom: 10px">
            </span>
              <div class="description" [innerHtml]="formatDescriptionData.shortDescription "></div>
            </div>

          </hid-loading-spinner>
          am
          <b>{{iteminfo.registrationData.promotion.date | date:'EEEE dd.MM.yyyy HH:mm'}}</b><span
          *ngIf="iteminfo.registrationData.promotion.address">  <br>
          <i class="fas fam fa-map-marker-alt"></i>{{iteminfo.registrationData.promotion.address}}
          </span>
          <br>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="iteminfo.registrationData.status === 'Success'" fxLayout="column" fxLayoutAlign="center center">
            <hid-checkbox (modelChange)="privacyConsentGiven = $event.valueOf()" inputClass="ita_check_privacy"
                          style="font-size: 14px; margin-top:20px;">
              Ich willige in die Verarbeitung gemäß der Erklärung auf der
              <a [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">
                Datenschutzseite
              </a>
              ein.
            </hid-checkbox>
            <span *ngIf="showPrivacyConsentError" class="hid-validationError" style="margin-left: 65px;">
            Bitte bestätige uns die Datenverarbeitung<br>
          </span>
            <button (click)="signUp()" class="hid-button primary ita_finalize-checkout  ita_finalize-checkout-oneclick">
              {{formatDescriptionData.ctaText}}
            </button>
            <div class="cta-description" [innerHTML]="formatDescriptionData.ctaDescription | safeHtml"></div>
          </div>
          <!-- Display warning/error and show alternate dates -->
          <p *ngIf="iteminfo.registrationData.status == 'Full' || iteminfo.registrationData.status == 'Unavailable'"
             class="text-red">Das Angebot ist leider nicht mehr
            verfügbar.</p>
        </div>
      </div>
      <div class="itemInfo mat-elevation-z3 white-background" [ngClass]="{'mat-elevation-z3': !mediaService.isMobile()}"
           fxLayout="column" fxLayoutAlign="space-between center"
           *ngIf="iteminfo.state === 'VALID' && iteminfo.registrationData.promotion.type != 'OfflineSeminarDate'
            && iteminfo.registrationData.promotion.type != 'SingleWebinarDate'
            && iteminfo.registrationData.promotion.type != 'UniqueEvent' ">
        <div fxLayout="column" fxLayoutAlign="start center" class="circle-box">
          <div class="mat-elevation-z1 circle" fxLayout="row" fxLayoutAlign="center center">
            <!--TODO different icons for different types -->
            <i class="fas fa-globe circle-item"></i>
          </div>
          <p class="item-info-title">{{iteminfo.registrationData.promotion.title}}</p>
          <hid-loading-spinner [loadingBool]="loadingDescription">
            <div fxLayout="column" fxLayoutAlign="center center">
              <span style="padding-bottom: 10px">
              <app-feedback-stars *ngIf="!loadingDescription"
                                  [rating]="formatDescriptionData.averageRating"></app-feedback-stars>
            </span>
              <div class="description" [innerHtml]="formatDescriptionData.shortDescription "></div>
            </div>
            <br>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div *ngIf="iteminfo.registrationData.status === 'Success'" fxLayout="column"
                   fxLayoutAlign="center center">
                <hid-checkbox (modelChange)="privacyConsentGiven = $event.valueOf()" fxLayout="column"
                              fxLayoutAlign="center center" inputClass="ita_check_privacy"
                              style="font-size: 14px; margin-top:20px;">
                  Ich willige in die Verarbeitung gemäß der Erklärung auf der
                  <a [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">
                    Datenschutzseite
                  </a>
                  ein.
                </hid-checkbox>
                <span *ngIf="showPrivacyConsentError" class="hid-validationError" style="margin-left: 65px;">
            Bitte bestätige uns die Datenverarbeitung<br>
          </span>
                <button (click)="signUp()"
                        class="hid-button primary ita_finalize-checkout  ita_finalize-checkout-oneclick">
                  {{formatDescriptionData.ctaText}}
                </button>
                <div class="cta-description" [innerHTML]="formatDescriptionData.ctaDescription | safeHtml"></div>
              </div>
              <p *ngIf="iteminfo.registrationData.status !== 'Success'" class="text-red">Das Angebot ist leider bereits
                ausgebucht</p>
              <br>
              <button (click)="goHome()" class="hid-button secondary inverse">
                Andere Angebote anschauen
              </button>
            </div>

          </hid-loading-spinner>
        </div>
      </div>
      <div class="itemInfo mat-elevation-z3 white-background" [ngClass]="{'mat-elevation-z3': !mediaService.isMobile()}"
           fxLayout="column" fxLayoutAlign="space-between center" *ngIf="iteminfo.state != 'VALID'">
        <div fxLayout="column" fxLayoutAlign="start center" class="circle-box">
          <div class="mat-elevation-z1 circle" fxLayout="row" fxLayoutAlign="center center">
            <i class="fa fa-times text-red" style="font-size: 50px"></i>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center" style="margin-top: 20px">
            <p style="font-size: 22px">Dein Link ist leider ungültig!</p>
            <p style="color: #777; text-align: center">Vielleicht hast du dich schon über den Link angemeldet?
              Kontaktiere uns, wenn du Hilfe brauchst!</p>
            <div class="logo" style="text-align: center;font-size: 42px;">😕</div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support
            kontaktieren
          </button>
        </div>
      </div>
    </div>
  </hid-loading-spinner>

</div>

<!--
  Invalid token section
-->

<div *ngIf="!loading && iteminfo.registrationData == null" fxLayout="row" fxLayoutAlign="center center">
  <div class="itemInfo mat-elevation-z3 white-background" [ngClass]="{'mat-elevation-z3': !mediaService.isMobile()}"
       fxLayout="column" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="start center" class="circle-box">
      <div class="mat-elevation-z1 circle" fxLayout="row" fxLayoutAlign="center center">
        <i class="fa fa-times text-red" style="font-size: 50px"></i>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" style="margin-top: 20px">
        <p style="font-size: 22px">Dein Link ist leider ungültig!</p>
        <p style="color: #777; text-align: center">Vielleicht hast du dich schon über den Link angemeldet?
          Kontaktiere uns, wenn du Hilfe brauchst!</p>
        <div class="logo" style="text-align: center;font-size: 42px;">
          😕
          <!--img src="../../../../../assets/img/wrongSmiley.jpg"-->
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support
        kontaktieren
      </button>
    </div>
  </div>

</div>
