import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';
import {RegisterComponent} from './register/register.component';
import {RouterModule, Routes} from "@angular/router";
import {GlobalModule} from "../global/global.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "./auth.service";
import {AccountResource} from "../generated/cronos/resources";
import {JwtModule} from "@auth0/angular-jwt";
import {environment} from "../../environments/environment";
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {InnoUtilsModule} from "../inno-utils/inno-utils.module";
import {AccountAuthGuardService} from "./account-auth-guard.service";
import {MatButtonModule} from "@angular/material/button";
import { TokenLoginComponent } from './token-login/token-login.component';
import { AccountSidenavComponent } from './account-sidenav/account-sidenav.component';
import { EditAvatarDialogComponent } from './profile/edit-avatar-dialog/edit-avatar-dialog.component';
import { RegisterPageComponent } from './register/register-page/register-page.component';

const appRoutes: Routes = [
  {path: 'account/login', component: LoginComponent},
  {path: 'account/profile', component: ProfileComponent, canActivate: [AccountAuthGuardService]},
  {path: 'account/register', component: RegisterComponent},
  {path: 'account/register-page', component: RegisterPageComponent},
  {path: 'account/password-reset', component: PasswordResetComponent},
  {path: 'token/login', component: TokenLoginComponent},
  {path: 'account/confirm-email', component: ConfirmEmailComponent}
];

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    PasswordResetComponent,
    TokenLoginComponent,
    ConfirmEmailComponent,
    AccountSidenavComponent,
    EditAvatarDialogComponent,
    RegisterPageComponent
  ],
    imports: [
        CommonModule,
        GlobalModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(appRoutes),
        JwtModule.forRoot({ // https://github.com/auth0/angular2-jwt
                config: {
                    tokenGetter: tokenGetter,
                    allowedDomains: [environment.cronosApiHost],
                    disallowedRoutes: [environment.cronosApiUrl + '/account/login']
                }
            }
        ),
        FlexLayoutModule,
        HidInteractionsModule,
        InnoUtilsModule,
        MatButtonModule,
    ],
  exports: [
    RouterModule,
    LoginComponent,
    RegisterComponent,
    AccountSidenavComponent
  ],
  providers: [
    AuthService,
    AccountResource
  ]
})
export class AccountModule {
}
