import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {EnterPromotionData, TokenFeedbackData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.WaitingListAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class WaitingListAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/waitingList/{formatId}/waitingList',
        endpoint: 'cronos'
    })
    private _enterWaitingList: IResourceMethodObservableStrict<EnterPromotionData, {}, {formatId: number}, void>;
    enterWaitingList(requestBody: EnterPromotionData, formatId: number): Promise<void> {
        return this._enterWaitingList(requestBody, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/waitinglist/unsub',
        endpoint: 'cronos'
    })
    private _unsubFromWaitingList: IResourceMethodObservableStrict<string, {}, {}, TokenFeedbackData>;
    unsubFromWaitingList(requestBody: string): Promise<TokenFeedbackData> {
        return this._unsubFromWaitingList(requestBody, null, null).toPromise()
    }

}
