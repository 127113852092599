<div id="checkout-header" fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 20px">
  <span>Gleich geschafft!</span>
  <button fxHide.xs class="hid-icon-button inverse ita_close_checkout-modal"
          (click)="globalCheckoutService.returnToOfferPage()"><i
    class="fas fa-times"></i>
  </button>
</div>


<div class="checkout-body">
  <div class="seminar-context-info">
    <!-- date was selected -->
    <div *ngIf="!seminarCheckoutService.waitingList">
      Workshop am <b>{{seminarCheckoutService.getSeminar().date | date: 'EEE dd.MM.yyyy'}}</b> um
      <b>{{seminarCheckoutService.getSeminar().date | date: 'HH:mm'}}</b>
    </div>

    <!-- going for waitinglist -->
    <div *ngIf="seminarCheckoutService.waitingList">
      Anmeldung zur Warteliste
    </div>


  </div>
  <hid-loading-spinner [loadingBool]="checkingOut">
    <form [formGroup]="contactForm" fxLayout="column">

      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_first-name" type="text" name="firstName"
                       [control]="contactForm.controls['firstName']" label="Vorname"></hid-input-field>
      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_last-name" type="text" name="lastName"
                       [control]="contactForm.controls['lastName']" label="Nachname"></hid-input-field>
      <app-hid-input-phone-number [checkoutAttempted]="checkoutAttempted" [showValidatorIcons]="true"
                                  inputFieldClass="ita_input_phone"
                                  name="phoneNumber" type="tel" [control]="contactForm.controls['phoneNumber']"
                                  label="Telefonnummer"
      ></app-hid-input-phone-number>


      <span class="inputIncentive">Damit wir dich über kurzfristige Änderungen informieren können.</span>
      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_email"
                       type="email" name="email" [control]="contactForm.controls['email']"
                       label="Email"></hid-input-field>
      <span class="inputIncentive">Damit wir dir die restlichen Infos schicken können.</span>

      <app-select-study-end-year [control]="contactForm.controls['studyEndYear']"></app-select-study-end-year>

      <div fxLayout="column">


        <hid-checkbox inputClass="ita_check_privacy"
                      style="font-size: 14px; margin-top:20px;"
                      [highlightErrors]="checkoutAttempted"
                      [control]="contactForm.controls['acceptPrivacy']">Ich willige in die Verarbeitung gemäß der
          Erklärung
          auf der <a [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">Datenschutzsseite</a> ein.
        </hid-checkbox>


        <span class="hid-validationError" style="    margin-left: 65px;"
              *ngIf="checkoutAttempted.value && contactForm.controls['acceptPrivacy'].invalid">Bitte bestätige uns die Datenverarbeitung<br></span>

        <!--steuer + fined-->
        <div *ngIf="this.localStorageService && this.localStorageService.getCurrentPage() &&
(this.localStorageService.getCurrentPage().selectedSplitId == 31168 ||
this.localStorageService.getCurrentPage().selectedSplitId == 31008 ||
this.localStorageService.getCurrentPage().selectedSplitId == 31003 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30985 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30979 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30942 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30920 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30893 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30867 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30857 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30785 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30778 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30766 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30749 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30739 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30671 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30653 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30623 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30405 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30400 ||
this.localStorageService.getCurrentPage().selectedSplitId == 29092 ||
this.localStorageService.getCurrentPage().selectedSplitId == 29001 ||
this.localStorageService.getCurrentPage().selectedSplitId == 28134 ||
this.localStorageService.getCurrentPage().selectedSplitId == 28129 ||
this.localStorageService.getCurrentPage().selectedSplitId == 28121 ||
this.localStorageService.getCurrentPage().selectedSplitId == 27749 ||
this.localStorageService.getCurrentPage().selectedSplitId == 27488 ||
this.localStorageService.getCurrentPage().selectedSplitId == 27427 ||
this.localStorageService.getCurrentPage().selectedSplitId == 26829 ||
this.localStorageService.getCurrentPage().selectedSplitId == 22471 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30807 ||
this.localStorageService.getCurrentPage().selectedSplitId == 30563)" style="padding:10px; font-size:14px">
          Mit deiner Anmeldung stimmst du zu, unsere <a [href]="'legal/taxGiveawayConditions'">Teilnahmebedingungen</a>
          zum Gewinnspiel gelesen und akzeptiert zu haben.
        </div>


        <div style="color: #777;padding-left:20px;  font-size: 12px">Bitte beachte außerdem unsere <a
          [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">Datenschutzbestimmungen</a></div>


      </div>
      <div fxLayout="column" *ngIf="additionalTermsRequired">


        <hid-checkbox inputClass="ita_check_terms-of-participation" style="font-size: 14px;"
                      [highlightErrors]="checkoutAttempted"
                      [control]="contactForm.controls['acceptAdditionalTerms']">Ich bin mit den <a
          [routerLink]="'/teilnahmebedingungen/' + page.cronosFormatId" target="_blank"
          class="ita_view_terms-of-participation">Teilnahmebedingungen</a>
          einverstanden.
        </hid-checkbox>
        <span class="hid-validationError" style="    margin-left: 65px;"
              *ngIf="checkoutAttempted.value && !contactForm.controls['acceptAdditionalTerms'].value">Bitte akzeptiere die Teilnahmebedingungen</span>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="button-row">
      <button class="hid-button inverse secondary" (click)="back()">Zurück</button>
      <button class="hid-button primary ita_finalize-checkout" (click)="attemptCheckout()">Platz sichern</button>
    </div>

  </hid-loading-spinner>
</div>
