import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HidDropdownComponent} from "./hid-dropdown/hid-dropdown.component";
import {HidInputFieldComponent} from "./hid-input-field/hid-input-field.component";
import {HidLoadingSpinnerComponent} from "./hid-loading-spinner/hid-loading-spinner.component";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HidDateFlowInputComponent} from "./hid-date-flow-input/hid-date-flow-input.component";
import {HidTextareaComponent} from "./hid-textarea/hid-textarea.component";
import {HidAsyncDropdownComponent} from "./hid-async-dropdown/hid-async-dropdown.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ClickOutsideModule} from "ng-click-outside";
import {HidCheckboxComponent} from './hid-checkbox/hid-checkbox.component';
import {HidSnackbarComponent} from "./hid-snackbar/hid-snackbar.component";
import {HidSnackbarService} from "./hid-snackbar/hid-snackbar.service";
import {AutosizeModule} from "ngx-autosize";
import {InnoUtilsModule} from "../inno-utils/inno-utils.module";
import {HidProgressSpinnerComponent} from "./hid-progress-spinner/hid-progress-spinner.component";
import {HidRadioGroupComponent} from './hid-radio-group/hid-radio-group.component';
import {HidStarRatingComponent} from './hid-star-rating/hid-star-rating.component';
import { HidLoadingButtonComponent } from './hid-loading-button/hid-loading-button.component';
import { HidUniversityAutocompleteComponent } from './hid-university-autocomplete/hid-university-autocomplete.component';
import {MatSelectModule} from "@angular/material/select";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {RouterModule} from "@angular/router";
import {HidInputPhoneNumberComponent} from "./hid-input-phone-number/hid-input-phone-number.component";
import {MatButtonModule} from "@angular/material/button";
import {CheckoutSeminardateSegmentationComponent} from './checkout-seminardate-segmentation/checkout-seminardate-segmentation.component';

@NgModule({
  declarations: [
    HidDropdownComponent,
    HidInputFieldComponent,
    HidLoadingSpinnerComponent,
    HidAsyncDropdownComponent,
    HidDateFlowInputComponent,
    HidTextareaComponent,
    HidCheckboxComponent,
    HidSnackbarComponent,
    HidProgressSpinnerComponent,
    HidRadioGroupComponent,
    HidStarRatingComponent,
    HidLoadingButtonComponent,
    HidUniversityAutocompleteComponent,
    HidInputPhoneNumberComponent,
    CheckoutSeminardateSegmentationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    ClickOutsideModule,
    AutosizeModule,
    InnoUtilsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    RouterModule,
    MatButtonModule,
  ],
  providers:[
    HidSnackbarService
  ],
  entryComponents:[
    HidSnackbarComponent
  ],
  exports: [
    HidDropdownComponent,
    HidInputFieldComponent,
    HidAsyncDropdownComponent,
    HidLoadingSpinnerComponent,
    HidDateFlowInputComponent,
    HidTextareaComponent,
    HidCheckboxComponent,
    HidProgressSpinnerComponent,
    HidRadioGroupComponent,
    HidTextareaComponent,
    HidStarRatingComponent,
    ClickOutsideModule,
    HidLoadingButtonComponent,
    HidUniversityAutocompleteComponent,
    HidInputPhoneNumberComponent,
    CheckoutSeminardateSegmentationComponent
  ]
})
export class HidInteractionsModule { }
